import PropTypes from 'prop-types'
import { forwardRef, memo } from 'react'
import classNames from '@/lib/util/classNames'
import * as styles from './ScaledText.module.scss'
import useScaledText from './hooks/useScaledText'

/**
 * The `ScaledText`
 */
const ScaledText = forwardRef((props, forwardedRef) => {
  const { children, className = '', input: inputText } = props
  const input = inputText || children
  const { measureRef, ref, wrapperRef } = useScaledText(props, forwardedRef)

  return (
    <>
      <div ref={ref} className={classNames(styles.output, className)}>{children}</div>
      <div ref={wrapperRef} className={styles.wrapper}>
        <div ref={measureRef} className={classNames(styles.measure, className)} aria-hidden="true">
          {input}
        </div>
      </div>
    </>
  )
})

ScaledText.displayName = 'ScaledText'

ScaledText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  renderRow: PropTypes.func,
  input: PropTypes.elementType
}

export default memo(ScaledText)
