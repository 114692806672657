import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import useIntersectionObserver from '@/lib/react/Intersection/hooks/useIntersectionObserver'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import { FXContext } from '../context'

const Intersect = forwardRef((props, forwardedRef) => {
  const { Tag = 'div', children, ...rest } = props
  const ref = useFallbackRef(forwardedRef)
  const { intersectionDelay, isIntersecting } = useIntersectionObserver(ref)
  const isActive = useDelayedActiveState(isIntersecting, intersectionDelay)
  const ctx = useMemo(() => ({ isActive }), [isActive])

  return (
    <FXContext.Provider value={ctx}>
      <Tag ref={ref} {...rest}>
        {children}
      </Tag>
    </FXContext.Provider>
  )
})

Intersect.displayName = 'Intersect'

Intersect.propTypes = {
  Tag: PropTypes.elementType,
  children: PropTypes.node
}

export default memo(Intersect)
