import PropTypes from 'prop-types'
import { memo, useCallback, useMemo } from 'react'
import { useRootContext } from '@/app/services/RootProvider/RootProvider'
import WedgeShape from '@/components/elements/WedgeShape/WedgeShape'
import classNames from '@/lib/util/classNames'
import useOption from '../hooks/useOption'
import * as styles from './Option.module.scss'

/**
 * The `Options
 * @param {object} props - the props
 * @returns {React.ReactElement} the element
 */
function Option (props) {
  const { label } = props
  const { setIsBurgerMenuOpen } = useRootContext()
  const {
    isHover,
    refBackground,
    refBlend,
    refButton,
    refWedge,
    setIsHover,
    uid
  } = useOption(props)
  const classNameOutput = useMemo(
    () => classNames(styles.container, isHover && styles.hover),
    [isHover]
  )
  const styleOutput = useMemo(
    () => ({
      '--mask-url': `url("#${uid}")`
    }),
    [uid]
  )

  const handleMouseOver = useCallback(() => {
    setIsHover(true)
  }, [])

  const handleMouseOut = useCallback(() => {
    setIsHover(false)
  }, [])

  const handleClick = useCallback(() => {
    setIsBurgerMenuOpen(false)
  }, [])

  return (
    <div className={classNameOutput} style={styleOutput}>
      <div ref={refBackground} className={styles.background} />
      <div ref={refBlend} className={styles.blend}>
        <div className={styles.blendInner} />
      </div>
      <button
        ref={refButton}
        className={styles.button}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
      >
        <div className={styles.content}>
          <span>{label}</span>
        </div>
      </button>
      <WedgeShape ref={refWedge} className={styles.wedge} svgId={uid} />
    </div>
  )
}

Option.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string
}

export default memo(Option)
