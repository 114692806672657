import { useEffect, useMemo, useRef, useState } from 'react'
import useIntersectionObserver from '@/lib/react/Intersection/hooks/useIntersectionObserver'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'

/**
 * The `useScrollingBlock`
 * @param {object} props - the props
 * @param {React.Ref} forwardedRef - the forwarded ref
 * @returns {object} the scrolling block context
 */
export default function useScrollingBlock (props, forwardedRef) {
  const ref = useFallbackRef(forwardedRef)
  const { isIntersecting } = useIntersectionObserver(ref)
  const [iterations, setIterations] = useState(0)
  const measureRef = useRef()
  const listRef = useRef()
  const [state] = useState({
    width: 0
  })

  useEffect(() => {
    const el = ref.current
    const measure = measureRef.current
    const handleResize = () => {
      const regionWidth = Math.max(50, el.clientWidth)
      const measuredWidth = Math.max(50, measure.clientWidth)
      const minWidth =
        Math.ceil((regionWidth + measuredWidth) / measuredWidth) *
        measuredWidth
      const iterationCount = Math.ceil(minWidth / measuredWidth)

      state.width = measuredWidth
      setIterations(prev => Math.max(prev, iterationCount))
    }

    const observer = new ResizeObserver(handleResize)

    observer.observe(el)
    observer.observe(measure)

    return () => {
      observer.disconnect()
    }
  }, [])

  const ctx = useMemo(
    () => ({
      ref,
      measureRef,
      listRef,
      iterations,
      isIntersecting,
      state
    }),
    [iterations, isIntersecting, state]
  )

  return ctx
}
