import data from 'app.data'
import PropTypes from 'prop-types'
import { createContext, useContext, useMemo, useState } from 'react'

export const RootContext = createContext({})

// Use root context utility
export const useRootContext = () => useContext(RootContext)

/**
 *
 * @param {object} props - the component props
 * @param {any} props.children - the children
 * @returns {React.ReactElement} the element
 */
export default function RootProvider ({ children }) {
  const appData = useMemo(() => data, [])
  const isRouteTransitioning = false

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false)

  const ctx = useMemo(
    () => ({ appData, isBurgerMenuOpen, setIsBurgerMenuOpen, isRouteTransitioning }),
    [appData, isBurgerMenuOpen, isRouteTransitioning]
  )

  return <RootContext.Provider value={ctx}>{children}</RootContext.Provider>
}

RootProvider.propTypes = {
  children: PropTypes.node
}
