export default `
<svg viewBox="0 0 100 115.4">
  <path d="M-10.9,37.7h0Z"/>
  <path d="M-49.9-9.8c.5-.2.7-.2.7,0,1,1.9,2.4,3.7,3.7,5.4,1.7,2.2,3.4,4.4,5.1,6.8.7,1.2,1.2,2.7,1.2,4.4v16.1c0,.5.2.7.7,1,6.3,2.9,12.7,6.3,19,9.5,1.7.7,3.2,1.7,4.4,3.4,4.4,6.1,9,12.2,13.4,18.3-2.4-5.1-6.1-10.2-8.8-15.6-.5-.7-1.2-2.4.2-1.2,3.4,1.7,6.8,3.4,10.2,5.1,1,.5,1.9.5,2.7-.2,2.4-1.2,4.9-2.9,7.5-4.4.5.2,0,.7-.2,1C6.6,45.5,3.2,51.3,0,57.2c-.2.5-.5.7-1,.2-3.9-2.7-8.3-4.6-12.2-7.1-11.7-6.8-23.9-13.9-35.5-20.2-1-.7-1.2-1-1.2-1.7,0-12.9.2-25.6,0-38.2,0,0,0-.2,0,0Z"/>
  <path d="M-10.9,37.7h0Z"/>
  <path d="M-.4,23.3c-6.8-3.9-13.9-7.1-20.7-10.7-.5-.2-.7-.5-.7-1V-10.8c0-1.5.5-2.2,1.7-2.9C-13.3-17.3-6.5-21,0-24.9c1.9-1.2,3.7,1.5,5.4,2.2,5.4,3.2,10.5,6.6,15.8,10,.2.2.5.2.5.7v7.8c.2,1.2-2.4-.7-3.7-1.2C12.2-8.3,6.6-11.5.8-14.6c-.5-.5-1-.5-1.7,0-3.4,1.9-6.6,3.9-10,5.8-.5.2-.7.5-.7,1V4.3c0,.7,0,1.2.7,1.5,7.3,3.4,14.4,7.3,21.7,11,1,.5.7,1,0,1.2-2.9,1.7-6.1,3.4-9,5.4-.5.5-1,.5-2.2,0Z"/>
  <path d="M22.2,10.7c0,1.2-1.5,2.2-2.7,1.5C12.2,8.7,5.4,4.6-1.9,1.2c-1.2-.7-1-2.7-1.7-3.9-.2-1,0-1.5.7-1.7,1.5-.5,2.7-1.5,3.9-.7C7.1-2,13.4,1.2,19.5,4.3c1.9,1,2.7,1.2,2.7,4.4"/>
  <path d="M16.6-36.6c-4.9-1.9-9-5.8-13.9-8.3-1.5-.5-2.4.2-3.7,1-7.3,3.9-14.4,8.3-21.7,11.7-4.4,1-9,1-13.4,1.5-3.9.5-8,.5-12.2,1.5,6.8.2,13.4.2,20.2.2-2.4,1-4.4,2.2-6.6,3.4-1.5,1-3.2,1.7-4.6,2.4-.5.2-.7.5-.7,1.2,0,3.9,0,7.8.2,11.7-2.4-4.1-4.9-8.5-7.3-12.7-1-1.7-1.7-3.4-2.9-5.1-.2-.5-.2-1,.5-1.2,10-5.4,19.5-11.4,29.2-16.8,6.6-3.9,13.1-7.5,19.7-11.4.5-.2,1-.2,1.5,0,8,4.9,16.1,9.5,24.1,14.1,2.9,1.5,5.6,3.2,8.5,4.9-1.2.2-2.2.5-3.4.5-4.4.7-8.8,1-13.6,1.5Z"/>
  <path d="M15.9,48.6c-.7.5-1.2,1.2-2.4,1.5,1.5-2.9,2.7-5.4,3.9-8,1.2-2.9,2.7-5.6,3.9-8.5.2-.7.7-1.2,1.5-1.7,5.4-3.2,10.7-6.3,16.3-9.3,1.2-.7,1.5-1.2,1.5-2.7-.2-8.3-.2-16.8-.5-25.1,0-1.7.2-3.2,1-4.6,2.2-4.4,4.1-8.8,6.3-13.1.5-.7,1.9-4.9.5-2.2-2.7,4.6-5.4,9-8,13.6.2-2.9-.2-5.8,0-8.8,0-1-.2-1.5-1-1.9-3.7-2.4-7.1-4.9-11-7.1h21.7c.7,0,.5.5.5.7V28.2c0,.7-.2,1-.7,1.2L15.9,48.6Z"/>
</svg>
`
