// extracted by mini-css-extract-plugin
var _1 = "USVDlz6lRPFlM0vfwrYC";
var _2 = "VcWJZgarE8P7TwlGf6Uo";
var _3 = "oqbA8SC1uwBMlgPz3kIl";
var _4 = "VCBVCiltp5SHFGupafyN";
var _5 = "jemJuOE5O0Od2ZhVZx46";
var _6 = "TvLvvH0mz30RLk4zAl97";
var _7 = "EQ47HpWtpe5TsGvV3bte";
var _8 = "xiBlJ2pRnUBckeXm7yJr";
var _9 = "CF8xkjsI0zH3Sbt95hB6";
var _a = "XexKc5wRnIks2TaPT8xv";
export { _1 as "bottom", _2 as "bottomLeft", _3 as "bottomRight", _4 as "container", _5 as "left", _6 as "ready", _7 as "right", _8 as "top", _9 as "topLeft", _a as "topRight" }
