import gsap from 'gsap'
import PropTypes from 'prop-types'
import { memo, useEffect, useMemo, useRef } from 'react'
import classNames from '@/lib/util/classNames'
import { useLoader } from '../../Provider/Provider'
import * as styles from './Bar.module.scss'

/**
 * The `Bar`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Bar (props) {
  const { className, isActive } = props
  const classNameOutput = useMemo(
    () => classNames(className, styles.container, isActive && styles.active),
    [className, isActive]
  )
  const { loadState } = useLoader()
  const progressRef = useRef()
  const info = useRef({
    artificialProgress: 0,
    progress: 0
  })
  const frame = useRef()

  useEffect(() => {
    const tick = () => {
      gsap.set(progressRef.current, {
        x: Math.min(0, -1 + loadState.progress) * 100 + '%'
      })

      if (info.current.progress < 1) {
        frame.current = requestAnimationFrame(tick)
      }
    }

    frame.current = requestAnimationFrame(tick)

    return () => {
      cancelAnimationFrame(frame.current)
    }
  }, [])

  return (
    <div className={classNameOutput}>
      <div ref={progressRef} className={styles.progress} />
    </div>
  )
}

/** @type {object} */
Bar.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool
}

// Memoize
export default memo(Bar)
