import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import * as styles from './Button.module.scss'

/**
 *
 */
const Button = forwardRef((props, forwardedRef) => {
  const {
    Tag = 'button',
    children,
    className = '',
    size = 'md',
    type = 'primary',
    ...rest
  } = props
  const ref = useFallbackRef(forwardedRef)
  const classNameOutput = useMemo(
    () => classNames(styles.button, className, styles[`type-${type}`], styles[`size-${size}`]),
    [className, type, size]
  )

  return (
    <Tag ref={ref} className={classNameOutput} {...rest}>
      <div className={styles.icon} />
      <span>{children}</span>
    </Tag>
  )
})

Button.displayName = 'Button'

Button.propTypes = {
  children: PropTypes.node,
  Tag: PropTypes.elementType,
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  size: PropTypes.string
}

export default memo(Button)
