import { createContext, useContext } from 'react'
import noop from '@/lib/util/noop'
import Controls from './Controls'
import Page from './Page/Page'
import Wrapper from './Wrapper'

export const ScrollContext = createContext({
  onScrollDown: noop
})

/**
 * The `useScroll` context
 * @returns {object} the context
 */
export function useScroll () {
  return useContext(ScrollContext)
}

export default {
  Controls,
  Page,
  Wrapper
}
