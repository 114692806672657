import PropTypes from 'prop-types'
import { memo, useMemo, useRef } from 'react'
// import constants from '@/app/constants'
import Spinner from '@/components/elements/Spinner/Spinner'
import Img from '@/lib/react/Img/Img'
import useIntersectionObserver from '@/lib/react/Intersection/hooks/useIntersectionObserver'
import { useImageSrcLoad } from '@/lib/react/hooks/useLoadImage'
import classNames from '@/lib/util/classNames'
import * as styles from './AdaptiveImage.module.scss'

/**
 * The `AdaptiveImage`
 * @param {object} props - the component props
 * @returns {React.ReactElement} - the element
 */
function AdaptiveImage (props) {
  const {
    className,
    fallbackSrc,
    path = '',
    sizes = [100, 250, 300],
    src: inputSrc,
    title,
    useSpinner = true,
    ...rest
  } = props

  const ref = useRef()
  const { isIntersecting } = useIntersectionObserver(ref)
  const { isError, isLoaded, onError, onLoad, src } = useImageSrcLoad(
    inputSrc,
    fallbackSrc,
    ref
  )
  const classNameOutput = useMemo(
    () => classNames(className, styles.container, isLoaded && styles.loaded),
    [className, isLoaded]
  )

  return (
    <div className={classNameOutput}>
      {src && !isError && (
        <>
          <Img
            ref={ref}
            src={src}
            onLoad={onLoad}
            onError={onError}
            title={title}
            sizes={sizes}
            path={path}
            {...rest}
          />
          <Spinner
            className={styles.spinner}
            isActive={isIntersecting && useSpinner && !isLoaded && !isError}
          />
        </>
      )}
      {(isError || !inputSrc) && <div className={styles.missing} />}
    </div>
  )
}

/** @type {object} */
AdaptiveImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.string,
  sizes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.number
    ])
  ),
  useSpinner: PropTypes.bool
}

// Export
export default memo(AdaptiveImage)
