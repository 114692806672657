import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import * as styles from './BurgerMenu.module.scss'
import Option from './Option/Option'

/**
 * THe `BurgerMenu`
 * @param {object} props - the component props
 * @param {boolean} props.isOpen - whether the menu is open
 * @param {object} props.options - the options
 * @returns {React.ReactElement} the element
 */
function BurgerMenu ({ isOpen, options }) {
  const classNameOutput = useMemo(() => classNames(styles.container), [open])

  return (
    <div className={classNameOutput}>
      <div className={styles.options}>
        {options.map((option, i) => (
          <Option key={i} {...option} delayShow={i * 0.1} isOpen={isOpen} />
        ))}
      </div>
    </div>
  )
}

BurgerMenu.propTypes = {
  isOpen: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape())
}

export default memo(BurgerMenu)
