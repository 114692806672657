import { ExtrudeGeometry } from 'three'
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js'
import { svgLoader } from '@/app/thread'
import { SVGLoader } from './SVGLoader'

const cache = {}
const DEFAULT_EXTRUDE_SETTINGS = {
  steps: 4,
  depth: 2,
  bevelEnabled: true,
  bevelThickness: 0.3,
  bevelSize: 0.2,
  bevelOffset: 0,
  bevelSegments: 2
}

/**
 * THe `getGeometryFromSVGString`
 * @param {string} svg - the input svg string
 * @param {number} scale - the scale
 * @param {object?} extrudeSettings - the extrude settings
 * @returns {object} the geometry
 */
export default function getGeometryFromSVGString (
  svg,
  scale = 1,
  extrudeSettings = DEFAULT_EXTRUDE_SETTINGS
) {
  const { paths } = svgLoader.parse(svg)
  const geometries = []
  const key = `${svg}_${scale}`

  if (cache[key]) {
    return cache[key]
  }

  for (let i = 0, len = paths.length; i < len; i += 1) {
    const path = paths[i]
    const shapes = SVGLoader.createShapes(path)

    for (let j = 0, jLen = shapes.length; j < jLen; j += 1) {
      const shape = shapes[j]

      geometries.push(new ExtrudeGeometry(shape, extrudeSettings))
    }
  }

  const merged = BufferGeometryUtils.mergeGeometries(geometries)

  merged.scale(scale / 10, scale / 10, scale / 10)

  cache[key] = merged

  return merged
}
