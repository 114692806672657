import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import * as styles from './ScrollFill.module.scss'

/**
 * The `ScrollFill`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function ScrollFill (props) {
  const { children, className } = props
  const classNameOutput = useMemo(
    () => classNames(styles.container, className),
    [className]
  )

  return (
    <div className={classNameOutput}>
      <div className={styles.fill}>
        {children}
      </div>
    </div>
  )
}

ScrollFill.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default memo(ScrollFill)
