import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import { getUniqueId } from '@/lib/util/uniq'
import * as styles from './WedgeShape.module.scss'
import useWedgeShape from './hooks/useWedgeShape'

/**
 * The `WedgeShape`
 */
const WedgeShape = forwardRef((props, forwardedRef) => {
  const { className, svgId = getUniqueId() } = props
  const { polygonMaskRef, polygonRef, ref, refBL, refBR, refTL, refTR } =
    useWedgeShape(props, forwardedRef)
  const classNameOutput = useMemo(
    () => classNames(styles.container, className),
    [className]
  )

  return (
    <div ref={ref} className={classNameOutput}>
      <svg
        className={styles.svg}
        preserveAspectRatio="none"
        viewBox="0 0 1 1"
      >
        <defs>
          <mask id={svgId} maskUnits="objectBoundingBox" maskContentUnits="objectBoundingBox">
            <polygon ref={polygonMaskRef} fill="white" stroke="none" />
          </mask>
        </defs>
        <polygon ref={polygonRef} vectorEffect="non-scaling-stroke" />
      </svg>
      <div ref={refTL} className={styles.wedgeTopLeft} />
      <div ref={refTR} className={styles.wedgeTopRight} />
      <div ref={refBL} className={styles.wedgeBottomLeft} />
      <div ref={refBR} className={styles.wedgeBottomRight} />
    </div>
  )
})

WedgeShape.displayName = 'WedgeShape'

WedgeShape.propTypes = {
  className: PropTypes.string,
  svgId: PropTypes.string
}

export default memo(WedgeShape)
