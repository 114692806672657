import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import * as styles from './Wipe.module.scss'
import useWipe from './hooks/useWipe'

const Wipe = forwardRef((props, forwardedRef) => {
  const { children, className, classNameContent, classNameWipe } = props
  const { boxRef, contentRef, ref, wipeRef } = useWipe(props, forwardedRef)
  const classNameOutput = useMemo(
    () => classNames(styles.wipe, className),
    [className]
  )
  const classNameContentOutput = useMemo(
    () => classNames(styles.content, classNameContent),
    [classNameContent]
  )
  const classNameWipeOutput = useMemo(
    () => classNames(styles.box, classNameWipe),
    [classNameWipe]
  )

  return (
    <div ref={ref} className={classNameOutput}>
      <div ref={contentRef} className={classNameContentOutput}>
        {children}
      </div>
      <div ref={wipeRef} className={styles.overlay}>
        <div ref={boxRef} className={classNameWipeOutput} />
      </div>
    </div>
  )
})

Wipe.displayName = 'Wipe'

Wipe.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  classNameWipe: PropTypes.string,
  delay: PropTypes.number,
  direction: PropTypes.oneOf(['down', 'left', 'right', 'up']),
  skippable: PropTypes.bool
}

export default memo(Wipe)
