import { useFrame, useThree } from '@react-three/fiber'
import PropTypes from 'prop-types'
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { normalize, lerpUnclamped } from '@/lib/util/interpolation'
import noop from '@/lib/util/noop'
import { useScroll } from '../FiberScroll'
import Canvas from './Canvas'
import HTML from './HTML'
import TrackedDOMElement from './TrackedDOMElement'

export const PageContext = createContext({
  pageHtmlOffset: 0,
  setPageHeight: noop
})

/**
 * The `useScroll` context
 * @returns {object} the context
 */
export function useScrollPage () {
  return useContext(PageContext)
}

/**
 * The `PageComponent`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function PageComponent (props) {
  const { children, paddingBottom = 0, pageIndex } = props
  const {
    canvasMaxPageWidth,
    containerHeight,
    containerWidth,
    maxWidth,
    pageHtmlHeights,
    pageHtmlOffsets,
    state,
    totalHeight
  } = useScroll()
  const { viewport } = useThree()
  const { height: viewportHeight, width: viewportWidth } = viewport
  const { addPage, removePage, updatePageSize } = state
  const [trackedElements, setTrackedElements] = useState(new Map())
  const [height, setPageHeight] = useState('100%')
  const [canvasPageOffset, setCanvasPageOffset] = useState(0)
  const [canvasPageHeight, setCanvasPageHeight] = useState(0)
  const pageHtmlOffset = useMemo(
    () => pageHtmlOffsets[pageIndex] || 0,
    [pageHtmlOffsets, pageIndex]
  )
  const pageHtmlHeight = useMemo(
    () => pageHtmlHeights[pageIndex] || 0,
    [pageHtmlHeights, pageIndex]
  )

  const pageState = useMemo(
    () => ({
      progress: 0,
      unclampedProgress: 0
    }),
    []
  )

  const trackDOMElement = useCallback((trackingId, position) => {
    setTrackedElements(lastElements => {
      const newElements = new Map([...lastElements])
      const last = newElements.get(trackingId)

      newElements.set(trackingId, {
        ...(last || {}),
        ...position
      })

      return newElements
    })
  }, [])

  const untrackDOMElement = useCallback(trackingId => {
    setTrackedElements(lastElements => {
      const newElements = new Map([...lastElements])

      newElements.delete(trackingId)

      return newElements
    })
  }, [])

  const refresh = () => {
    const progressFactor = 1 / (totalHeight - containerHeight) || 0
    const offsetStart = (pageHtmlOffset - containerHeight) * progressFactor
    const offsetEnd = (pageHtmlOffset + pageHtmlHeight) * progressFactor

    pageState.progress = normalize(offsetStart, offsetEnd, state.offset) || 0

    pageState.unclampedProgress =
      lerpUnclamped(offsetStart, offsetEnd, state.offset) || 0
  }

  useLayoutEffect(() => {
    setCanvasPageOffset(
      -(pageHtmlOffset / containerHeight) * viewportHeight || 0
    )

    setCanvasPageHeight(
      (pageHtmlHeight / containerHeight) * viewportHeight || 0
    )
  }, [viewportWidth, containerWidth, pageHtmlOffset])

  useFrame(refresh)

  useLayoutEffect(() => {
    addPage(pageIndex)

    return () => {
      removePage(pageIndex)
    }
  }, [pageIndex])

  useLayoutEffect(() => {
    updatePageSize(pageIndex, height, paddingBottom)
    refresh()
  }, [pageIndex, height, paddingBottom])

  const ctx = useMemo(
    () => ({
      setPageHeight,
      pageHtmlOffset,
      pageState,
      canvasMaxPageWidth,
      canvasPageOffset,
      canvasPageHeight,
      trackDOMElement,
      untrackDOMElement,
      trackedElements,
      maxWidth,
      pageIndex
    }),
    [
      setPageHeight,
      pageHtmlOffset,
      pageState,
      canvasMaxPageWidth,
      canvasPageOffset,
      canvasPageHeight,
      trackedElements,
      maxWidth,
      pageIndex
    ]
  )

  return <PageContext.Provider value={ctx}>{children}</PageContext.Provider>
}

PageComponent.propTypes = {
  children: PropTypes.node,
  pageIndex: PropTypes.number,
  paddingBottom: PropTypes.number
}

const Page = memo(PageComponent)

Page.HTML = HTML
Page.Canvas = Canvas
Page.TrackedDOMElement = TrackedDOMElement

export default Page
