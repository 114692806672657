import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import * as styles from './SlideScroll.module.scss'
import useSlideScroll from './hooks/useSlideScroll'

const SlideScroll = forwardRef((props, forwardedRef) => {
  const { listRef, ref } = useSlideScroll(props, forwardedRef)
  const { children, className } = props
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )

  return (
    <div ref={ref} className={classNameOutput}>
      <div ref={listRef} className={styles.list}>
        {children}
      </div>
    </div>
  )
})

SlideScroll.displayName = 'SlideScroll'

SlideScroll.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default memo(SlideScroll)
