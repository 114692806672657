import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import FX from '@/components/core/FX/FX'
import Layout from '@/components/core/Layout/Layout'
import BlockText from '@/components/elements/BlockText/BlockText'
import ClipWedge from '@/components/elements/ClipWedge/ClipWedge'
import JumboText from '@/components/elements/JumboText/JumboText'
import PageScrollProgress from '@/components/elements/PageScrollProgress/PageScrollProgress'
import ScrollFill from '@/components/elements/ScrollFill/ScrollFill'
import ScrollRotate from '@/components/elements/ScrollRotate/ScrollRotate'
import SplitTextRows from '@/components/util/SplitTextRows/SplitTextRows'
import SVGDial from '@/resources/svg/ui/dial.svg'
import Page from '@/lib/react/FiberScroll/Page/Page'
import classNames from '@/lib/util/classNames'
import * as styles from './Content.module.scss'

/**
 * The `Content`
 * @param {object} props - the component props
 * @param {string} props.className - the class name
 * @returns {React.ReactElement} the element
 */
function Content ({ className }) {
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )

  return (
    <Layout.Section className={classNameOutput}>
      <PageScrollProgress className={styles.grid}>
        <div className={styles.media}>
          <Page.TrackedDOMElement
            trackingId="backgroundBox"
            className={styles.box}
          />
        </div>
        <div className={styles.header}>
          <div className={styles.banner}>
            <JumboText
              className={styles.title}
              input="$ELDO Token"
              fill={
                <ScrollFill>
                  <div className={styles.shape} />
                </ScrollFill>
              }
            >
              $ELDO Token
            </JumboText>
          </div>
          <FX.Intersect className={styles.subtitle}>
            <SplitTextRows
              renderRow={(children, i) => (
                <ClipWedge Tag={FX.Wipe} key={i} skippable>
                  <BlockText Tag="h4" theme="secondary">
                    {children}
                  </BlockText>
                </ClipWedge>
              )}
            >
              <BlockText Tag="h4">Access To A Global RWA Portfolio</BlockText>
            </SplitTextRows>
          </FX.Intersect>
        </div>
        <div className={styles.body}>
          <div className={styles.group}>
            <ScrollRotate className={styles.ring} factor={0.5}>
              <SVGDial />
            </ScrollRotate>
            <ScrollRotate className={styles.ring} factor={0.5} offset={90}>
              <SVGDial />
            </ScrollRotate>
          </div>
        </div>
      </PageScrollProgress>
    </Layout.Section>
  )
}

Content.propTypes = {
  className: PropTypes.string
}

export default memo(Content)
