import PropTypes from 'prop-types'
import { forwardRef, memo } from 'react'
import classNames from '@/lib/util/classNames'
import noop from '@/lib/util/noop'
import * as styles from './SplitTextRows.module.scss'
import useSplitTextRows from './hooks/useSplitTextRows'

/**
 * The `SplitTextRows`
 */
const SplitTextRows = forwardRef((props, forwardedRef) => {
  const { children, className = '', renderRow = noop } = props
  const { len, measureRef, ref, rows } = useSplitTextRows(props, forwardedRef)

  return (
    <div ref={ref} className={styles.container}>
      <div className={classNames(styles.output, className)}>
        {rows.map((content, i) => renderRow(content, i, len))}
      </div>
      <div ref={measureRef} className={classNames(styles.measure, className)}>
        {children}
      </div>
    </div>
  )
})

SplitTextRows.displayName = 'SplitTextRows'

SplitTextRows.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  renderRow: PropTypes.func
}

export default memo(SplitTextRows)
