import PropTypes from 'prop-types'
import { memo, useLayoutEffect, useMemo, useRef, useState } from 'react'
import classNames from '@/lib/util/classNames'
import { ScrollContext, useScroll } from '../FiberScroll'
import * as styles from './HTML.module.scss'
import { PageContext, useScrollPage } from './Page'

/**
 * The `HTMLContent`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function HTMLContentComponent (props) {
  const { children, className, fullHeight = true, style = {} } = props
  const { pageHtmlOffset, setPageHeight } = useScrollPage()
  const ref = useRef()
  const [didPosition, setDidPosition] = useState(false)
  const classNameOutput = useMemo(
    () =>
      classNames(
        className,
        styles.container,
        (className || !fullHeight) && styles.override,
        didPosition && styles.ready
      ),
    [className, fullHeight, didPosition]
  )
  const currentSize = useRef(0)
  const styleOutput = useMemo(
    () => ({
      ...style,
      '--scroll-page-offset': `${pageHtmlOffset}px`
    }),
    [style, pageHtmlOffset]
  )

  useLayoutEffect(() => {
    const handleResize = () => {
      const nextSize = ref.current.clientHeight

      if (nextSize !== currentSize.current) {
        currentSize.current = nextSize
        setPageHeight(`${nextSize}px`)
        setDidPosition(true)
      }
    }

    const observer = new ResizeObserver(handleResize)

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div ref={ref} className={classNameOutput} style={styleOutput}>
      {children}
    </div>
  )
}

HTMLContentComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  setPageHeight: PropTypes.func,
  fullHeight: PropTypes.bool,
  pageHtmlOffset: PropTypes.number,
  style: PropTypes.shape()
}

const HTMLContent = memo(HTMLContentComponent)

/**
 * The `HTML`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function HTML (props) {
  const scrollContext = useScroll()
  const pageContext = useScrollPage()
  const { state } = scrollContext
  const { In } = state

  return (
    <In>
      <ScrollContext.Provider key={pageContext.pageIndex} value={scrollContext}>
        <PageContext.Provider value={pageContext}>
          <HTMLContent {...props} />
        </PageContext.Provider>
      </ScrollContext.Provider>
    </In>
  )
}

export default memo(HTML)
