import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import * as styles from './People.module.scss'
import Person from './Person/Person'

/**
 * The `People` component
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function People (props) {
  const { className, profiles } = props
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )

  return (<div className={classNameOutput}>
    {profiles.map((person, i) => <Person key={i} {...person} />)}
  </div>)
}

People.propTypes = {
  className: PropTypes.string,
  profiles: PropTypes.array
}

export default memo(People)
