import { useCallback, useEffect, useMemo, useState } from 'react'

/**
 * The `useImageSrcLoad` hook
 * @param {string} image - the image path to load
 * @param {string} fallbackImage - the fallback image (if available)
 * @param {object} ref - the ref
 * @returns {object} the context
 */
export function useImageSrcLoad (image, fallbackImage, ref) {
  const [src, setSrc] = useState(image)
  const [isUsingFallback, setIsUsingFallback] = useState(false)
  const [isLoaded, setIsLoaded] = useState(true)
  const [isError, setIsError] = useState(false)

  /**
   * On load callback
   */
  const onLoad = useCallback(() => {
    setIsError(false)
    setIsLoaded(true)
  }, [src])

  /**
   * On error
   */
  const onError = useCallback(() => {
    if (!isUsingFallback && fallbackImage) {
      // Errored, but has a fallback image, try that
      setIsError(false)
      setIsLoaded(false)
      setIsUsingFallback(true)
      setSrc(fallbackImage)

      return
    }

    setIsError(true)
  }, [src, fallbackImage, isUsingFallback])

  /**
   * Effect: When the `image` changes
   */
  useEffect(() => {
    setIsUsingFallback(false)
    setIsError(false)
    setIsLoaded(false)
    setSrc(image)
  }, [image])

  useEffect(() => {
    if (ref?.current && ref?.current?.complete) {
      setIsLoaded(true)
      setIsError(false)
    }
  }, [])

  /**
   * Memo: the context to return
   */
  const ctx = useMemo(
    () => ({
      src,
      onLoad,
      onError,
      isLoaded,
      isError
    }),
    [src, onLoad, onError, isLoaded, isError]
  )

  // Return the context
  return ctx
}
