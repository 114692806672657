// extracted by mini-css-extract-plugin
var _1 = "Yfe0tv_Q_wYAf2GHhjBi";
var _2 = "_8GInXCDrYq3YDD0M2yH";
var _3 = "mfJdu4WKzoIW4SLsPXKo";
var _4 = "PSixSNe2Sg0t0bNk7Nfa";
var _5 = "B32bUJvHDQAuajxLERrT";
var _6 = "NuM_c8U0gQKk7hXyu035";
var _7 = "wEmmEjvtVpntoHXZEArB";
var _8 = "ExrLdDAkiIqHNfInhq3L";
export { _1 as "box", _2 as "container", _3 as "content", _4 as "rule", _5 as "ruler", _6 as "shape", _7 as "skew", _8 as "title" }
