import { context as fiberContext, useFrame } from '@react-three/fiber'
import PropTypes from 'prop-types'
import React, {
  forwardRef,
  memo,
  useContext,
  useImperativeHandle,
  useRef
} from 'react'
import { createPortal } from 'react-dom'
import { useScroll } from './FiberScroll'
import ScrollPrompt from './ScrollPrompt/ScrollPrompt'
import { useCanvasContainer } from '@/gl/CanvasContainer/CanvasContainer'

const ScrollHtml = forwardRef(
  ({ onScrollDown, scrollPrompt, style, ...props }, ref) => {
    const { hasScrolled, state } = useScroll()
    const group = useRef(null)
    const { contentTunnel } = useCanvasContainer()

    useImperativeHandle(ref, () => group.current, [])

    const fiberState = useContext(fiberContext)

    useFrame(() => {
      if (state.delta > state.eps || state.needsSync) {
        state.needsSync = false

        if (!group?.current?.style) {
          return
        }

        group.current.style.transform = `translate3d(${
          state.horizontal ? state.totalScroll * state.offset : 0
        }px,${state.horizontal ? 0 : state.totalScroll * -state.offset}px, 0)`
      }
    })

    return (
      <contentTunnel.In>
        <ScrollPrompt hasScrolled={hasScrolled} onScrollDown={onScrollDown}>
          {scrollPrompt}
        </ScrollPrompt>
        {createPortal(
          <fiberContext.Provider value={fiberState}>
            <div
              ref={group}
              style={{
                ...style,
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                willChange: 'transform',
                pointerEvents: 'all'
              }}
              {...props}
            />
          </fiberContext.Provider>,
          state.fixed
        )}
      </contentTunnel.In>
    )
  }
)

ScrollHtml.displayName = 'ScrollHtml'

ScrollHtml.propTypes = {
  style: PropTypes.shape(),
  scrollPrompt: PropTypes.node,
  onScrollDown: PropTypes.func
}

export default memo(ScrollHtml)
