import gsap from 'gsap'
import { useEffect, useMemo, useRef } from 'react'
import { useLoader } from '@/components/interface/Loader/Provider/Provider'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import { useFXContext } from '../../context'

/**
 * The `useWipe` hook
 * @param {object} props - the input props
 * @param {object} forwardedRef - the forwarded ref
 * @returns {object} the context
 */
export default function useWipe (props, forwardedRef) {
  const { delay = 0, direction = 'right', duration = 0.5, skippable = false } = props
  const { isReady } = useLoader()
  const hasLoaded = useDelayedActiveState(isReady, 0.5)
  const { isActive: isFXActive } = useFXContext()
  const isActive = useDelayedActiveState(isFXActive && hasLoaded, delay, 0, skippable)
  const wasActive = useRef(isFXActive && hasLoaded)
  const ref = useFallbackRef(forwardedRef)
  const contentRef = useRef()
  const wipeRef = useRef()
  const boxRef = useRef()

  useEffect(() => {
    const content = contentRef.current
    const wipe = wipeRef.current
    const box = boxRef.current

    if (isActive && isActive === wasActive.current) {
      content.style.visibility = 'visible'
      wipe.style.display = 'none'

      return
    }

    wasActive.current = isActive

    if (!isActive) {
      content.style.visibility = 'hidden'
      wipe.style.display = 'block'

      return
    }

    content.style.visibility = 'hidden'
    wipe.style.display = 'block'

    // Determine the animation property and offset values based on the direction
    let property = 'x'
    let initialValue = '-100%'
    let exitValue = '100%'

    switch (direction) {
      case 'left':
        property = 'x'
        initialValue = '100%'
        exitValue = '-100%'

        break
      case 'up':
        property = 'y'
        initialValue = '100%'
        exitValue = '-100%'

        break
      case 'down':
        property = 'y'
        initialValue = '-100%'
        exitValue = '100%'

        break
      // default: 'right'
      default:
        property = 'x'
        initialValue = '-100%'
        exitValue = '100%'
    }

    gsap.set(box, { x: 0, y: 0 })
    // Set initial position of the box
    gsap.set(box, { [property]: initialValue })

    // Animate in to the center, then reveal content and animate out
    gsap.to(box, {
      [property]: '0%',
      duration: duration * 0.5,
      ease: 'power1.inOut',
      onComplete: () => {
        content.style.visibility = 'visible'

        gsap.to(box, {
          [property]: exitValue,
          duration: duration * 0.5,
          ease: 'power1.inOut',
          onComplete: () => {
            wipe.style.display = 'none'
          }
        })
      }
    })

    return () => {
      gsap.killTweensOf(box)
    }
  }, [isActive, direction, duration])

  const ctx = useMemo(
    () => ({
      ref,
      contentRef,
      wipeRef,
      boxRef
    }),
    []
  )

  return ctx
}
