import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import Button from '@/components/core/Button/Button'
import FX from '@/components/core/FX/FX'
import Layout from '@/components/core/Layout/Layout'
import BlockText from '@/components/elements/BlockText/BlockText'
import ClipWedge from '@/components/elements/ClipWedge/ClipWedge'
import ScrollingBlock from '@/components/elements/ScrollingBlock/ScrollingBlock'
import SyncScroll from '@/components/elements/ScrollingBlock/behaviors/SyncScroll'
import WedgeShape from '@/components/elements/WedgeShape/WedgeShape'
import SplitTextRows from '@/components/util/SplitTextRows/SplitTextRows'
import SVGRuler from '@/resources/svg/ui/ruler.svg'
import classNames from '@/lib/util/classNames'
import * as styles from './HeroContent.module.scss'

/**
 *
 * @param {object} props - the props
 * @param {string} props.className - the class name
 * @returns {React.ReactElement} the element
 */
function HeroContent ({ className }) {
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )

  return (
    <Layout.Section className={classNameOutput}>
      <div className={styles.box}>
        <WedgeShape className={styles.shape} />
        <div className={styles.content}>
          <ScrollingBlock
            className={styles.skew}
            behavior={<SyncScroll factor={0.1} />}
          >
            <div>| | | | | | | | | |&nbsp;</div>
          </ScrollingBlock>
          <FX.Intersect className={styles.title}>
            <SplitTextRows
              renderRow={(children, i) => (
                <ClipWedge Tag={FX.Wipe} key={i} skippable>
                  <BlockText theme="invert">{children}</BlockText>
                </ClipWedge>
              )}
            >
              <BlockText size="xl">RWA Incubator & Defi Platform</BlockText>
            </SplitTextRows>
            <Button>Explore</Button>
          </FX.Intersect>
        </div>
      </div>
      <ScrollingBlock
        className={styles.ruler}
        behavior={<SyncScroll factor={-0.1} />}
      >
        <div className={styles.rule}>
          <SVGRuler />
        </div>
      </ScrollingBlock>
    </Layout.Section>
  )
}

HeroContent.propTypes = {
  className: PropTypes.string
}

export default memo(HeroContent)
