import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import Layout from '@/components/core/Layout/Layout'
import JumboText from '@/components/elements/JumboText/JumboText'
import PageScrollProgress from '@/components/elements/PageScrollProgress/PageScrollProgress'
import ScrollFill from '@/components/elements/ScrollFill/ScrollFill'
import ScrollRotate from '@/components/elements/ScrollRotate/ScrollRotate'
import SVGDial from '@/resources/svg/ui/dial.svg'
import classNames from '@/lib/util/classNames'
import * as styles from './Content.module.scss'

/**
 * The `Content`
 * @param {object} props - the component props
 * @param {string} props.className - the class name
 * @returns {React.ReactElement} the element
 */
function Content ({ className }) {
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )

  return (
    <Layout.Section className={classNameOutput}>
      <PageScrollProgress className={styles.grid}>
        <div className={styles.header}>
          <div>
            <JumboText
              className={styles.title}
              input="Advisors"
              fill={
                <ScrollFill>
                  <div className={styles.shape} />
                </ScrollFill>
              }
            >
              Team &
            </JumboText>
            <JumboText
              className={styles.title}
              input="Advisors"
              fill={
                <ScrollFill>
                  <div className={styles.shape2} />
                </ScrollFill>
              }
            >
              Advisors
            </JumboText>
          </div>
          <p className={styles.body}>
            Our team and advisors&apos; expertise span: Crypto, Blockchain, Legal,
            Energy, Commodities, Finance, Branding & Marketing
          </p>
        </div>
        <div className={styles.rings}>
          <ScrollRotate className={styles.ring}>
            <SVGDial />
          </ScrollRotate>
          <ScrollRotate className={styles.ring} offset={90}>
            <SVGDial />
          </ScrollRotate>
          <ScrollRotate className={styles.ring} offset={180}>
            <SVGDial />
          </ScrollRotate>
          <ScrollRotate className={styles.ring} offset={270}>
            <SVGDial />
          </ScrollRotate>
        </div>
      </PageScrollProgress>
    </Layout.Section>
  )
}

Content.propTypes = {
  className: PropTypes.string
}

export default memo(Content)
