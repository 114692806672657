/**
 * Apply conditional properties to an object
 * @param {Array} conditionalProps - the array of conditional props
 * @returns {object} the result
 */
function conditionalProperties (...conditionalProps) {
  return conditionalProps.reduce((obj, props) => {
    if (props) {
      return { ...obj, ...props }
    }

    return obj
  }, {})
}

export default conditionalProperties
