import { useEffect, useMemo, useRef } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import { cleanRect } from '@/lib/util/rect'

/**
 * The `useWedgeShape` hook
 * @param {object} props - the component props
 * @param {object} forwardedRef - the forwarded ref
 * @returns {object} the context object
 */
export default function useWedgeShape (props, forwardedRef) {
  const ref = useFallbackRef(forwardedRef)
  const refTL = useRef()
  const refTR = useRef()
  const refBL = useRef()
  const refBR = useRef()
  const polygonRef = useRef()
  const polygonMaskRef = useRef()

  useEffect(() => {
    const el = ref.current
    const tl = refTL.current
    const tr = refTR.current
    const bl = refBL.current
    const br = refBR.current

    if (!el || !tl || !tr || !bl || !br) return

    const handleResize = () => {
      // Get container dimensions
      const rect = cleanRect(el.getBoundingClientRect())
      // Get wedge dimensions (assuming square)
      const rectTL = cleanRect(tl.getBoundingClientRect())
      const rectTR = cleanRect(tr.getBoundingClientRect())
      const rectBL = cleanRect(bl.getBoundingClientRect())
      const rectBR = cleanRect(br.getBoundingClientRect())

      const containerWidth = rect.width
      const containerHeight = rect.height

      const tlSize = rectTL.width
      const trSize = rectTR.width
      const blSize = rectBL.width
      const brSize = rectBR.width

      const p1x = (tlSize / containerWidth) * 1
      const p1y = 0
      const p2x = 1 - (trSize / containerWidth) * 1
      const p2y = 0
      const p3x = 1
      const p3y = (trSize / containerHeight) * 1
      const p4x = 1
      const p4y = 1 - (brSize / containerHeight) * 1
      const p5x = 1 - (brSize / containerWidth) * 1
      const p5y = 1
      const p6x = (blSize / containerWidth) * 1
      const p6y = 1
      const p7x = 0
      const p7y = 1 - (blSize / containerHeight) * 1
      const p8x = 0
      const p8y = (tlSize / containerHeight) * 1

      const points = [
        `${p1x},${p1y}`,
        `${p2x},${p2y}`,
        `${p3x},${p3y}`,
        `${p4x},${p4y}`,
        `${p5x},${p5y}`,
        `${p6x},${p6y}`,
        `${p7x},${p7y}`,
        `${p8x},${p8y}`
      ].join(' ')

      if (polygonRef.current) {
        polygonRef.current.setAttribute('points', points)
      }

      if (polygonMaskRef.current) {
        polygonMaskRef.current.setAttribute('points', points)
      }
    }

    // Create a ResizeObserver to recalc on any change
    const observer = new ResizeObserver(handleResize)

    observer.observe(el)
    observer.observe(tl)
    observer.observe(tr)
    observer.observe(bl)
    observer.observe(br)

    // Run once initially
    handleResize()

    return () => {
      observer.disconnect()
    }
  }, [ref])

  const ctx = useMemo(
    () => ({
      ref,
      refTL,
      refTR,
      refBL,
      refBR,
      polygonRef,
      polygonMaskRef
    }),
    [ref, refTL, refTR, refBL, refBR, polygonRef, polygonMaskRef]
  )

  return ctx
}
