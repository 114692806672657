import gsap from 'gsap'
import { useCallback, useEffect, useRef } from 'react'

/**
 *
 * @param {object} ref - the ref object
 * @param {boolean} isActive - whether is active
 * @param {object} props - the config props
 * @param {number} durationShow - show duration
 * @param {number} durationHide - hide duration
 */
export default function useOrganicReveal (
  ref,
  isActive,
  props,
  durationShow = 1,
  durationHide = 0.5
) {
  const {
    rotationX = 0,
    rotationY = 0,
    rotationZ = 0,
    scale = 1,
    x = 0,
    y = 0,
    z = 0
  } = props
  const wasActive = useRef(false)

  const show = useCallback(target => {
    gsap.to(target.position, {
      x: 0,
      y: 0,
      z: 0,
      duration: durationShow,
      ease: 'elastic.out(0.1)'
    })

    gsap.to(target.rotation, {
      x: 0,
      y: 0,
      z: 0,
      duration: durationShow,
      ease: 'elastic.out(0.2)'
    })

    if (scale < 1) {
      gsap.to(target.scale, {
        x: 1,
        y: 1,
        z: 1,
        duration: durationShow,
        ease: 'elastic.out(0.2)'
      })
    }
  }, [])

  const hide = useCallback((target, isInstant = false) => {
    gsap.to(target.position, {
      x,
      y,
      z,
      duration: isInstant ? 0 : durationHide,
      ease: 'elastic.out(0.2)'
    })

    gsap.to(target.rotation, {
      x: rotationX,
      y: rotationY,
      z: rotationZ,
      duration: isInstant ? 0 : durationHide,
      ease: 'elastic.out(0.2)'
    })

    if (scale < 1) {
      gsap.to(target.scale, {
        x: scale,
        y: scale,
        z: scale,
        duration: isInstant ? 0 : durationHide,
        ease: 'elastic.out(0.2)'
      })
    }
  }, [])

  useEffect(() => {
    wasActive.current = false
    hide(ref.current, true)
  }, [])

  useEffect(() => {
    const target = ref?.current

    if (!target || isActive === wasActive.current) {
      return
    }

    wasActive.current = isActive
    gsap.killTweensOf(target.rotation)
    gsap.killTweensOf(target.position)
    gsap.killTweensOf(target.scale)

    if (isActive) {
      show(target)
    } else {
      hide(target)
    }

    return () => {
      gsap.killTweensOf(target.rotation)
      gsap.killTweensOf(target.position)
      gsap.killTweensOf(target.scale)
    }
  }, [isActive])
}
