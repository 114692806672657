import { memo, useCallback } from 'react'
import { useRootContext } from '@/app/services/RootProvider/RootProvider'
import useAppData from '@/app/services/hooks/useAppData'
import Button from '@/components/core/Button/Button'
import FX from '@/components/core/FX/FX'
import Layout from '@/components/core/Layout/Layout'
import SVGLogo from '@/resources/svg/logo/standard.svg'
import BurgerMenu from '../BurgerMenu/BurgerMenu'
import ButtonBurger from '../ButtonBurger/ButtonBurger'
import * as styles from './Header.module.scss'

/**
 * The `Header`
 * @returns {React.ReactElement} the element
 */
function Header () {
  const appData = useAppData()
  const { isBurgerMenuOpen, setIsBurgerMenuOpen } = useRootContext()

  const handleToggle = useCallback(() => {
    setIsBurgerMenuOpen(prev => !prev)
  }, [])

  return (
    <>
      <Layout.Section Tag="header" className={styles.container}>
        <div className={styles.bar}>
          <div className={styles.left}>
            <FX.Switch isActive={true} className={styles.logo}>
              <FX.Wipe>
                <SVGLogo />
              </FX.Wipe>
            </FX.Switch>
            <ButtonBurger onToggle={handleToggle} open={isBurgerMenuOpen} />
          </div>
          <div className={styles.menu}>
            <BurgerMenu isOpen={isBurgerMenuOpen} options={appData.sections} />
          </div>
          <div className={styles.right}>
            <Button size="sm">Sign up</Button>
          </div>
        </div>

      </Layout.Section>
    </>
  )
}

export default memo(Header)
