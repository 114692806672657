import PropTypes from 'prop-types'
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'
import constants from '@/app/constants'
import conditionalProperties from '@/lib/util/conditionalProperties'
import noop from '@/lib/util/noop'
import useFallbackRef from '../hooks/useFallbackRef'
import ImgContextWrapper, { useImgContext } from './ImgContextWrapper'
import useImage from './useImage'

/**
 * The `Img`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
const ImgComponent = forwardRef((props, forwardedRef) => {
  const {
    className,
    onError,
    onLoad,
    path,
    sizes,
    src: inputSrc,
    transform,
    ...rest
  } = props
  const ref = useFallbackRef(forwardedRef)
  const { onError: onErrorContext, onLoad: onLoadContext } = useImgContext()
  const { src, srcSet } = useImage(props)
  const attributes = useMemo(
    () =>
      conditionalProperties(!!srcSet && src.indexOf('/') !== 0 && { srcSet }),
    [src, srcSet]
  )
  const wasLoaded = useRef(false)

  /**
   * Handle image load
   */
  const handleOnLoad = useCallback(
    e => {
      if (wasLoaded.current) {
        return
      }

      wasLoaded.current = true
      onLoadContext()
      onLoad(e)
    },
    [onLoad]
  )

  /**
   * Handle image error
   */
  const handleOnError = useCallback(
    e => {
      onErrorContext()
      onError(e)
    },
    [onError]
  )

  /**
   * Effect: On src change
   */
  useEffect(() => {
    wasLoaded.current = false

    if (ref.current?.complete) {
      handleOnLoad(ref.current)
    }
  }, [])

  /**
   * When the `src` changes, reset the `wasLoaded`
   */
  useEffect(() => {
    wasLoaded.current = false
  }, [src])

  return (
    <img
      ref={ref}
      {...rest}
      {...attributes}
      src={src}
      onLoad={handleOnLoad}
      onError={handleOnError}
    />
  )
})

// Display name
ImgComponent.displayName = 'Img'

/** @type {object} */
ImgComponent.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  transform: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  }),
  sizes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.number
    ])
  ),
  path: PropTypes.string,
  onLoad: PropTypes.func,
  onError: PropTypes.func,
  loading: PropTypes.string
}

/** @type {object} */
ImgComponent.defaultProps = {
  className: '',
  src: null,
  transform: null,
  onLoad: noop,
  onError: noop,
  path: constants.PATH_CDN,
  sizes: [],
  loading: 'lazy'
}

// Memoize
const Img = memo(ImgComponent)

// Bind the extras
Img.Context = ImgContextWrapper

// Export
export default Img
