import { useEffect, useMemo, useRef } from 'react'
import onResize from '@/lib/onResize'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import { cleanRect } from '@/lib/util/rect'

/**
 * The `useSplitTextRows`
 * @param {object} props - the props
 * @param {object} forwardedRef - the forwarded ref
 * @returns {object} the context object
 */
export default function useSplitTextRows (props, forwardedRef) {
  const { children, input } = props
  const ref = useFallbackRef(forwardedRef)
  const measureRef = useRef()
  const wrapperRef = useRef()

  useEffect(() => {
    const el = ref.current
    const wrapper = wrapperRef.current
    const measure = measureRef.current
    let frame

    const handleResize = () => {
      cancelAnimationFrame(frame)

      frame = requestAnimationFrame(() => {
        const wrapperRect = cleanRect(wrapper.getBoundingClientRect())
        const measureRect = cleanRect(measure.getBoundingClientRect())
        const scale = Number(
          (wrapperRect.width / measureRect.width).toFixed(3)
        )

        el.style.setProperty('--width', `${measureRect.width * scale}px`)
        el.style.setProperty('--scale', scale)
      })
    }

    const observer = new ResizeObserver(handleResize)

    observer.observe(wrapper)
    onResize.on(handleResize, 0)
    handleResize()

    return () => {
      cancelAnimationFrame(frame)
      observer.disconnect()
      onResize.off(handleResize, 0)
    }
  }, [children, input])

  const ctx = useMemo(() => ({
    ref,
    measureRef,
    wrapperRef
  }), [])

  return ctx
}
