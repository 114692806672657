import { memo } from 'react'
// import WorldMap from '@/components/WorldMap/WorldMap'
import ScrollPrompt from '@/components/interface/ScrollPrompt/ScrollPrompt'
import FiberScroll from '@/lib/react/FiberScroll/FiberScroll'
import Assets from './sections/Assets/Assets'
import Banner from './sections/Banner/Banner'
import Hero from './sections/Hero/Hero'
import Team from './sections/Team/Team'
import TeamHeader from './sections/TeamHeader/TeamHeader'
import Token from './sections/Token/Token'
import TokenDetail from './sections/TokenDetail/TokenDetail'
import FooterSection from './shared/FooterSection/FooterSection'
import { useCanvasContainer } from '@/gl/CanvasContainer/CanvasContainer'
import Lights from '@/gl/Lights/Lights'

/**
 * The `Home`
 * @returns {React.ReactElement} the element
 */
function Home () {
  const { canvasTunnel } = useCanvasContainer()

  return (
    <canvasTunnel.In>
      <FiberScroll.Controls key="home" damping={0.05} distance={1} scrollPrompt={<ScrollPrompt />}>
        <Hero />
        <Banner />
        <Token />
        <TokenDetail />
        <Assets />
        <TeamHeader />
        <Team />
        <FooterSection />
      </FiberScroll.Controls>
      <Lights />
    </canvasTunnel.In>
  )
}

export default memo(Home)
