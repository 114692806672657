import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import * as styles from './Section.module.scss'

/**
 * The `Section`
 * @param {object} props - the component props
 * @param {any} props.Tag - the tag
 * @param {string} props.className - the class name
 * @param {any} props.children - the children
 * @param {boolean} props.grid - the grid
 * @returns {React.ReactElement} the element
 */
function Section ({ Tag = 'div', children, className = '', grid = false }) {
  const classNameOutput = useMemo(
    () => classNames(styles.container, className, grid && styles.grid),
    [className, grid]
  )

  return <Tag className={classNameOutput}>{children}</Tag>
}

Section.propTypes = {
  children: PropTypes.node,
  Tag: PropTypes.elementType,
  className: PropTypes.string,
  grid: PropTypes.bool
}

export default memo(Section)
