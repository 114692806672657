import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import useAppData from '@/app/services/hooks/useAppData'
import FX from '@/components/core/FX/FX'
import Layout from '@/components/core/Layout/Layout'
import BlockText from '@/components/elements/BlockText/BlockText'
import ClipWedge from '@/components/elements/ClipWedge/ClipWedge'
import SplitTextRows from '@/components/util/SplitTextRows/SplitTextRows'
import classNames from '@/lib/util/classNames'
import * as styles from './Content.module.scss'
import People from '@/pages/Home/shared/People/People'

/**
 * The `Content`
 * @param {object} props - the component props
 * @param {string} props.className - the class name
 * @returns {React.ReactElement} the element
 */
function Content ({ className }) {
  const appData = useAppData()
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )

  console.log(appData)

  return (
    <Layout.Section className={classNameOutput}>
      <div className={styles.grid}>
        <FX.Intersect className={styles.right}>
          <SplitTextRows
            renderRow={(children, i) => (
              <ClipWedge Tag={FX.Wipe} key={i} skippable>
                <BlockText Tag="h2" theme="invert">
                  {children}
                </BlockText>
              </ClipWedge>
            )}
          >
            <BlockText Tag="h2">Our Team</BlockText>
          </SplitTextRows>

          <FX.Transition className={styles.full}>
            <People profiles={appData.team.team} />
          </FX.Transition>
        </FX.Intersect>
        <FX.Intersect className={styles.right}>
          <SplitTextRows
            renderRow={(children, i) => (
              <ClipWedge Tag={FX.Wipe} key={i} skippable>
                <BlockText Tag="h2">
                  {children}
                </BlockText>
              </ClipWedge>
            )}
          >
            <BlockText Tag="h2">Our Advisors</BlockText>
          </SplitTextRows>
          <FX.Transition className={styles.full}>
            <People profiles={appData.team.advisors} />
          </FX.Transition>
        </FX.Intersect>
      </div>
    </Layout.Section>
  )
}

Content.propTypes = {
  className: PropTypes.string
}

export default memo(Content)
