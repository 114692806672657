import Intersect from './Intersect/Intersect'
import Switch from './Switch/Switch'
import Transition from './Transition/Transition'
import Wipe from './Wipe/Wipe'

export default {
  Intersect,
  Switch,
  Wipe,
  Transition
}
