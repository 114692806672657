import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import * as styles from './ScrollingBlock.module.scss'
import { ScrollingBlockContext } from './context'
import useScrollingBlock from './hooks/useScrollingBlock'

const ScrollingBlock = forwardRef((props, forwardedRef) => {
  const { behavior, children, className } = props
  const ctx = useScrollingBlock(props, forwardedRef)
  const { iterations, listRef, measureRef, ref } = ctx
  const classNameOutput = useMemo(
    () => classNames(styles.container, className),
    [className]
  )

  const items = useMemo(
    () =>
      Array.from({ length: iterations }, (_, index) => (
        <div className={styles.group} key={index}>
          {children}
        </div>
      )),
    [children, iterations]
  )

  return (
    <ScrollingBlockContext.Provider value={ctx}>
      <div ref={ref} className={classNameOutput}>
        <div ref={measureRef} className={styles.measure}>
          <div className={styles.group}>{children}</div>
        </div>
        <div ref={listRef} className={styles.list}>
          {items}
        </div>
      </div>
      {behavior}
    </ScrollingBlockContext.Provider>
  )
})

ScrollingBlock.displayName = 'ScrollingBlock'

ScrollingBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  behavior: PropTypes.node
}

export default memo(ScrollingBlock)
