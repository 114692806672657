/**
 * Mutation that shuffles an existing array
 * @param {Array} array - the input array
 * @returns {Array} the shuffled array (not required, but provided)
 */
export const shuffle = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    [array[i], array[j]] = [array[j], array[i]]
  }

  return array
}

/**
 * Get the symmetric difference between two array
 * @param {Array} arr1 - the first array
 * @param {Array} arr2 - the second array
 * @returns {Array} the diff
 */
export const symmetricDifference = (arr1, arr2) =>
  arr1
    .filter(x => !arr2.includes(x))
    .concat(arr2.filter(x => !arr1.includes(x)))

/**
 * Array difference
 * @param {Array} array1 - the first array
 * @param {Array} array2 - the array items to remove from the first
 * @returns {Array} the result
 */
export const arrayDifference = (array1, array2) =>
  array1.filter(item => !array2.includes(item))

/**
 * Confirms whether 2 simple arrays of values match.
 * Values can appear in any order
 * @param {Array} arr1 - the first array
 * @param {Array} arr2 - the second array
 * @returns {boolean} whether the arrays match
 */
export const arraysMatch = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  const set1 = new Set(arr1)
  const set2 = new Set(arr2)

  for (const id of set1) {
    if (!set2.has(id)) {
      return false
    }
  }

  return true
}

export const arrayRandom = arr => arr[Math.floor(Math.random() * arr.length)]

/**
 * Divide an array into smaller arrays
 * @param {Array} inputArray - the input array
 * @param {number} numArrays - the number of arrays to divide into
 * @returns {Array} the divided arrays
 */
export const divideArray = (inputArray, numArrays = 1) => {
  const divisions = []
  const numChunks = Math.min(numArrays, inputArray.length)
  const chunkSize = Math.ceil(inputArray.length / numChunks)
  let inputArrayIndex = 0

  for (let i = 0; i < numChunks; i++) {
    const chunk = inputArray.slice(
      inputArrayIndex,
      inputArrayIndex + chunkSize
    )

    divisions.push(chunk)
    inputArrayIndex += chunk.length
  }

  return divisions
}
