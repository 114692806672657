import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import * as styles from './ButtonBurger.module.scss'

/**
 * The Burger button
 * @param {object} props - the component props
 * @param {boolean} props.open - the open state
 * @param {Function} props.onToggle - the toggle function
 * @returns {React.ReactElement} the element
 */
function ButtonBurger ({ onToggle, open }) {
  const classNameOutput = useMemo(
    () => classNames(styles.button, open && styles.open),
    [open]
  )

  return (
    <button className={classNameOutput} onClick={onToggle}>
      <div className={styles.bars}>
        <div className={styles.bar} />
        <div className={styles.bar} />
        <div className={styles.bar} />
      </div>
      <svg
        className={styles.close}
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <g>
          <line
            x1="20"
            y1="0"
            x2="80"
            y2="100"
            vectorEffect="non-scaling-stroke"
          />
          <line
            x1="20"
            y1="100"
            x2="80"
            y2="0"
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </svg>
    </button>
  )
}

ButtonBurger.propTypes = {
  open: PropTypes.bool,
  onToggle: PropTypes.func
}

export default memo(ButtonBurger)
