import PropTypes from 'prop-types'
import { memo } from 'react'
import AdaptiveImage from '@/components/elements/AdaptiveImage/AdaptiveImage'
import ClipWedge from '@/components/elements/ClipWedge/ClipWedge'
import * as styles from './Person.module.scss'

/**
 * The `Person` component
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Person (props) {
  const { img, name } = props

  return (
    <div className={styles.container}>
      <ClipWedge className={styles.box}>
        <div className={styles.image}>
          <AdaptiveImage
            src={img}
            sizes={[
              [400, 400],
              [800, 800]
            ]}
          />
        </div>
      </ClipWedge>
      <div className={styles.name} direction="up">
        {name}
      </div>
    </div>
  )
}

Person.propTypes = {
  name: PropTypes.string,
  img: PropTypes.string
}

export default memo(Person)
