import { Canvas } from '@react-three/fiber'
import PropTypes from 'prop-types'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import classNames from '@/lib/util/classNames'
import { useLoader } from '../Provider/Provider'
import Bar from './Bar/Bar'
import * as styles from './Display.module.scss'
import Scene from './Scene/Scene'

// This is the worker thread that will render the scene
const worker = new Worker(new URL('./worker.jsx', import.meta.url), {
  type: 'module'
})

/**
 * The `Display`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Display (props) {
  const { className } = props
  const { isLoaderActive, isLoaderRendered, startLoader } = useLoader()
  const classNameOutput = useMemo(
    () =>
      classNames(
        className,
        styles.container,
        isLoaderActive && styles.active,
        isLoaderRendered && styles.render
      ),
    [className, isLoaderActive, isLoaderRendered]
  )
  const bc = useRef()
  const [workerLoaded, setWorkerLoaded] = useState(false)

  useEffect(() => {
    // Listen to worker messages
    worker.onmessage = event => {
      if (event.data.type === 'startLoad') {
        bc.current = new BroadcastChannel('loader_channel')
        setWorkerLoaded(true)
        startLoader()
      }
    }

    return () => {
      // Cleanup on component unmount
      worker.terminate()
    }
  }, [])

  useEffect(() => {
    if (!workerLoaded) {
      return
    }

    if (isLoaderActive) {
      bc.current.postMessage({ type: 'action', value: true })

      return
    }

    bc.current.postMessage({ type: 'action', value: false })
  }, [workerLoaded, isLoaderActive])

  return (
    <div className={classNameOutput}>
      <div className={styles.background}>
        <Canvas frameloop="demand">
          <Scene isActive={isLoaderActive} />
        </Canvas>
        <Bar isActive={isLoaderActive} />
      </div>
    </div>
  )
}

/** @type {object} */
Display.propTypes = {
  className: PropTypes.string
}

// Memoize
export default memo(Display)
