import { useThree } from '@react-three/fiber'
import { useMemo } from 'react'
import { useScrollPage } from '../Page'

/**
 * The `useScrollPageTrackedElement` hook convert DOM element positions
 * to canvas based positions
 * @param {string} trackingId - the tracking id
 * @returns {object} the tracked position
 */
export default function useScrollPageTrackedElement (trackingId) {
  const { trackedElements } = useScrollPage()
  const { size, viewport } = useThree()

  const trackedElement = useMemo(
    () => trackedElements.get(trackingId),
    [trackedElements]
  )

  const [x, y, width, height, isIntersecting] = useMemo(() => {
    if (!trackedElement) {
      return [0, 0, 1, 1, true]
    }

    const ratioWidth = viewport.width / size.width
    const ratioHeight = viewport.height / size.height
    const cWidth = ratioWidth * trackedElement.width
    const cHeight = ratioHeight * trackedElement.height
    const cX =
      ratioWidth * (-size.width * 0.5 + trackedElement.x) + cWidth * 0.5
    const cY = ratioHeight * -trackedElement.y - cHeight * 0.5

    return [cX, cY, cWidth, cHeight, trackedElement.isIntersecting]
  }, [trackedElement, size, viewport])

  const ctx = useMemo(
    () => ({
      position: [x, y, 0],
      width,
      height
    }),
    [x, y, width, height, isIntersecting]
  )

  return ctx
}
