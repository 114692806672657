import { useFrame } from '@react-three/fiber'
import gsap from 'gsap'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useScrollPage } from '@/lib/react/FiberScroll/Page/Page'
import useIntersectionObserver from '@/lib/react/Intersection/hooks/useIntersectionObserver'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import { normalize } from '@/lib/util/interpolation'

/**
 * The `useSlideScroll`
 * @param {object} props - the component props
 * @param forwardedRef
 * @returns {object} the context object
 */
export default function useSlideScroll (props, forwardedRef) {
  const ref = useFallbackRef(forwardedRef)
  const { isIntersecting } = useIntersectionObserver(ref)
  const { pageState } = useScrollPage()
  const listRef = useRef()
  const [state] = useState({
    containerWidth: 0,
    listWidth: 0
  })
  const lastProgress = useRef(0)

  useEffect(() => {
    const el = ref.current
    const list = listRef.current

    const handleResize = () => {
      state.containerWidth = el.clientWidth
      state.listWidth = list.clientWidth
    }

    const observer = new ResizeObserver(handleResize)

    observer.observe(el)
    observer.observe(list)

    return () => {
      observer.disconnect()
    }
  }, [])

  useFrame((inputState, delta) => {
    if (!isIntersecting) {
      return
    }

    const progress = normalize(0.25, 0.65, pageState.progress)
    const x = (-state.listWidth + state.containerWidth) * progress

    if (x === lastProgress.current) {
      return
    }

    lastProgress.current = x

    gsap.to(listRef.current, {
      x,
      duration: 0.2
    })
  })

  const ctx = useMemo(() => ({
    ref,
    listRef
  }), [])

  return ctx
}
