import PropTypes from 'prop-types'
import { memo, useCallback, useMemo } from 'react'
import { useScrollPromptContext } from '@/lib/react/FiberScroll/ScrollPrompt/ScrollPrompt'
import classNames from '@/lib/util/classNames'
import * as styles from './ScrollPrompt.module.scss'

/**
 * The `ScrollPrompt`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function ScrollPrompt (props) {
  const { className } = props
  const { isActive, isVisible, onScrollDown } = useScrollPromptContext()
  const classNameOutput = useMemo(
    () =>
      classNames(
        className,
        styles.container,
        isActive && styles.active,
        isVisible && styles.visible
      ),
    [className, isActive, isVisible]
  )

  const handleClick = useCallback(() => {
    onScrollDown()
  }, [onScrollDown])

  return (
    <div className={classNameOutput} onClick={handleClick}>
      <div className={styles.content}>
        <div className={styles.box}>
          <svg viewBox="0 0 1 1" preserveAspectRatio="none">
            <polygon fill="var(--black)" points="0,1 0.5,0 1,1" />
            <polygon fill="var(--orange-500)" points="0.35,0.5 0.5,0.8 0.65,0.5">
              {isActive && (
                <animateTransform
                  attributeName="transform"
                  type="translate"
                  values="0,0; 0,-0.05; 0,0; 0,0.05; 0,0"
                  dur="1.5s"
                  repeatCount="indefinite"
                />
              )}
            </polygon>
            <polygon fill="var(--black)" points="0.4,0.4 0.5,0.6 0.6,0.4" />
          </svg>
        </div>
      </div>
    </div>
  )
}

ScrollPrompt.propTypes = {
  className: PropTypes.string
}

export default memo(ScrollPrompt)
