// extracted by mini-css-extract-plugin
var _1 = "C2FlKKJi7CxZcJk50uCb";
var _2 = "Fy3ZGjkqELsnugxF8IYA";
var _3 = "_XCxcVpXwPgGo64k28v1";
var _4 = "SduaYrnWP_hxBIIFXX5c";
var _5 = "eEQeWLIEm85oDUHFtbzw";
var _6 = "wo1YuG5iFdxISCux0fOb";
var _7 = "erE6octCK0Xo6BxTuj7V";
var _8 = "g_egcsncmkyDM9K3R7x_";
var _9 = "MDhRsO5QzpgDeaLoW00g";
export { _1 as "body", _2 as "container", _3 as "grid", _4 as "header", _5 as "ring", _6 as "rings", _7 as "shape", _8 as "shape2", _9 as "title" }
