import gsap from 'gsap'
import { useEffect, useMemo, useRef, useState } from 'react'
import { getUniqueId } from '@/lib/util/uniq'

/**
 * The `useOption` hook
 * @param {object} props - the component props
 * @returns {object} the context object
 */
export default function useOption (props) {
  const { delayShow, isOpen } = props
  const wasOpen = useRef(false)
  const refBackground = useRef()
  const refBlend = useRef()
  const refButton = useRef()
  const refWedge = useRef()
  const uid = useMemo(() => `svg-${getUniqueId()}`, [])
  const [isHover, setIsHover] = useState(false)

  useEffect(() => {
    if (isOpen === wasOpen.current) {
      return
    }

    const background = refBackground.current
    const blend = refBlend.current
    const button = refButton.current
    const wedge = refWedge.current

    gsap.killTweensOf([background, blend, button, wedge])

    const target = isOpen ? '0%' : -170
    const delay = isOpen ? delayShow : delayShow * 0.3

    gsap.to(background, { duration: 0.9, delay, y: target, ease: 'elastic.out(0.3)' })
    gsap.to(blend, { duration: 0.9, delay, y: target, ease: 'elastic.out(0.3)' })
    gsap.to(button, { duration: 0.9, delay, y: target, ease: 'elastic.out(0.3)' })
    gsap.to(wedge, { duration: 0.9, delay, y: target, ease: 'elastic.out(0.3)' })

    wasOpen.current = isOpen
  }, [isOpen, delayShow])

  useEffect(() => {
    wasOpen.current = false

    const background = refBackground.current
    const blend = refBlend.current
    const button = refButton.current
    const wedge = refWedge.current

    const target = -170

    gsap.set(background, { y: target })
    gsap.set(blend, { y: target })
    gsap.set(button, { y: target })
    gsap.set(wedge, { y: target })
  }, [])

  const ctx = useMemo(() => ({
    uid,
    isHover,
    setIsHover,
    refBackground,
    refBlend,
    refButton,
    refWedge
  }), [isHover])

  return ctx
}
