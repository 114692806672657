import { useFrame } from '@react-three/fiber'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLoader } from '@/components/interface/Loader/Provider/Provider'
import { useScrollPage } from '@/lib/react/FiberScroll/Page/Page'

/**
 * The `useIsPageInView`
 * @returns {boolean} whether the page is in view
 */
export default function useIsPageInView () {
  const { isReady } = useLoader()
  const [isActive, setIsActive] = useState(false)
  const { pageState } = useScrollPage()
  const wasActive = useRef(false)
  const isLoaded = useRef(false)

  useFrame((state, delta) => {
    if (
      pageState.unclampedProgress > 0.2 &&
      pageState.unclampedProgress < 1.15
    ) {
      if (isLoaded.current && !wasActive.current) {
        wasActive.current = true
        setIsActive(true)
      }
    } else if (
      pageState.unclampedProgress < -0.09 ||
      pageState.unclampedProgress >= 1.16
    ) {
      if (wasActive.current) {
        wasActive.current = false
        setIsActive(false)
      }
    }
  })

  useEffect(() => {
    if (isReady) {
      isLoaded.current = true
    }
  }, [isReady])

  useLayoutEffect(() => {
    wasActive.current = false
  }, [])

  return isActive
}
