import { memo } from 'react'
import Footer from '@/components/interface/Footer/Footer'
import FiberScroll from '@/lib/react/FiberScroll/FiberScroll'

/**
 * The `FooterSection`
 * @param props
 * @returns {React.ReactElement} the element
 */
function FooterSection (props) {
  return (
    <FiberScroll.Page {...props} paddingBottom={20}>
      <FiberScroll.Page.HTML fullHeight={false}>
        <Footer />
      </FiberScroll.Page.HTML>
    </FiberScroll.Page>
  )
}

export default memo(FooterSection)
