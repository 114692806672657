import { Suspense } from 'react'
import * as styles from './App.module.scss'
import Frame from './components/interface/Frame/Frame'
import Header from './components/interface/Header/Header'
import Loader from './components/interface/Loader/Loader'
import CanvasContainer from './gl/CanvasContainer/CanvasContainer'
import Home from './pages/Home/Home'

/**
 *
 */
export default function App () {
  return (
    <div className={styles.container}>
      <CanvasContainer>
        <Suspense fallback={<div />}>
          <Home />
        </Suspense>
        <Loader.GLSuspense />
        <Header />
        <Loader.Display />
        <Frame />
      </CanvasContainer>
    </div>
  )
}
