import PropTypes from 'prop-types'
import { memo, useCallback, useMemo } from 'react'
import SplitTextRows from '@/components/util/SplitTextRows/SplitTextRows'
import classNames from '@/lib/util/classNames'
import * as styles from './Link.module.scss'
import useLink from './hooks/useLink'

/**
 * The `Link`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Link (props) {
  const { Tag = 'a', children, className } = props
  const { isHoverIn, isHoverOut, ref, setIsHover } = useLink(props)
  const classNameOutput = useMemo(
    () => classNames(styles.container, className, isHoverIn && styles.in, isHoverOut && styles.out),
    [className, isHoverIn, isHoverOut]
  )

  const handleMouseOver = useCallback(() => {
    setIsHover(true)
  }, [setIsHover])

  const handleMouseOut = useCallback(() => {
    setIsHover(false)
  }, [setIsHover])

  return (
    <Tag ref={ref} className={classNameOutput} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <SplitTextRows
        renderRow={(child, i) => (
          <div key={i} className={styles.block}>
            <span>{child}</span>
            <div className={styles.bar} />
          </div>
        )}
      >
        {children}
      </SplitTextRows>
    </Tag>
  )
}

Link.propTypes = {
  Tag: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string
}

export default memo(Link)
