import { useFrame, useThree } from '@react-three/fiber'
import PropTypes from 'prop-types'
import { forwardRef, memo, useImperativeHandle, useRef } from 'react'
import { useScroll } from '../FiberScroll'
import { useScrollPage } from './Page'

const Canvas = forwardRef(({ children }, ref) => {
  const { containerHeight, state, totalHeight } = useScroll()
  const group = useRef(null)
  const { canvasPageOffset } = useScrollPage()
  const { height } = useThree(state => state.viewport)

  useImperativeHandle(ref, () => group.current, [])

  useFrame(() => {
    group.current.position.y =
      canvasPageOffset +
      height *
        ((totalHeight - containerHeight) / containerHeight) *
        state.offset +
      height * 0.5
  })

  return <group ref={group}>{children}</group>
})

Canvas.displayName = 'Canvas'

Canvas.propTypes = {
  children: PropTypes.node
}

export default memo(Canvas)
