import { memo, useMemo } from 'react'
import SVGCorner from '@/resources/svg/frame/corner.svg'
import SVGHorizontal from '@/resources/svg/frame/horizontal.svg'
import SVGLeft from '@/resources/svg/frame/vertical.svg'
import classNames from '@/lib/util/classNames'
import { useLoader } from '../Loader/Provider/Provider'
import * as styles from './Frame.module.scss'

/**
 * The `Frame`
 * @returns {React.ReactElement} the element
 */
function Frame () {
  const { isReady } = useLoader()
  const classNameOutput = useMemo(() => classNames(styles.container, isReady && styles.ready), [isReady])

  return (
    <div className={classNameOutput}>
      <div className={styles.top} />
      <div className={styles.left}>
        <SVGLeft preserveAspectRatio="none" />
      </div>

      <div className={styles.right}>
        <SVGLeft preserveAspectRatio="none" />
      </div>

      <div className={styles.bottom}>
        <SVGHorizontal preserveAspectRatio="none" />
      </div>

      <SVGCorner className={styles.topLeft} />
      <SVGCorner className={styles.topRight} />
      <SVGCorner className={styles.bottomLeft} />
      <SVGCorner className={styles.bottomRight} />
    </div>
  )
}

export default memo(Frame)
