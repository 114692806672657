import PropTypes from 'prop-types'
import React, { Suspense, memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import { normalizeSizeString } from '@/lib/util/sizes'
import * as styles from './Icon.module.scss'

const IconComponent = React.lazy(
  () => import(/* webpackChunkName: "icon" */ './IconComponent')
)

/**
 * The `Icon` component renders a icon
 * based on the input `icon` type string. If unmatched
 * the icon will not be renderered (refer to the supported
 * list `ICONS`)
 *
 * Note: Lazy loads the `IconComponent`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Icon (props) {
  const { className, icon, size, ...rest } = props
  const classNameOutput = useMemo(
    () =>
      classNames([
        className,
        styles.icon,
        styles[`icon-${icon}`],
        styles[`size-${normalizeSizeString(size)}`]
      ]),
    [className, icon, size]
  )

  return (
    <Suspense
      fallback={
        <svg className={classNameOutput} aria-hidden="true" {...rest} />
      }
    >
      <IconComponent className={classNameOutput} icon={icon} {...rest} />
    </Suspense>
  )
}

/** @type {object} */
Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string
}

// Memoize
export default memo(Icon)
