import { ScreenQuad } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { BackgroundMaterial } from './BackgroundMaterial/BackgroundMaterial'

/**
 * The `BackgroundScene`
 * @param {object} props - the input props
 * @param {string} props.colorStart - the start color
 * @param {string} props.colorEnd - the end color
 * @returns {React.ReactElement} the element
 */
export default function BackgroundScene ({ colorEnd = '#812621', colorStart = '#a53c37' }) {
  const { gl, size } = useThree()
  const resolution = useMemo(
    () => [size.width * gl.getPixelRatio(), size.height * gl.getPixelRatio()],
    [size.width, size.height, gl]
  )

  return (
    <ScreenQuad scale={100} depthWrite={false} position={[0, 0, -10]}>
      <backgroundMaterial
        key={BackgroundMaterial.key}
        depthWrite={false}
        colorStart={colorStart}
        colorEnd={colorEnd}
        resolution={resolution}
      />
    </ScreenQuad>
  )
}

BackgroundScene.propTypes = {
  colorStart: PropTypes.string,
  colorEnd: PropTypes.string
}
