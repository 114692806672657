import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import { FXContext } from '../context'

const Switch = forwardRef((props, forwardedRef) => {
  const { Tag = 'div', children, isActive, ...rest } = props
  const ref = useFallbackRef(forwardedRef)
  const ctx = useMemo(() => ({ isActive }), [isActive])

  return (
    <FXContext.Provider value={ctx}>
      <Tag ref={ref} {...rest}>
        {children}
      </Tag>
    </FXContext.Provider>
  )
})

Switch.displayName = 'Switch'

Switch.propTypes = {
  Tag: PropTypes.elementType,
  isActive: PropTypes.bool,
  children: PropTypes.node
}

export default memo(Switch)
