import { useFrame, useThree } from '@react-three/fiber'
import { easing } from 'maath'
import PropTypes from 'prop-types'
import React, {
  Children,
  cloneElement,
  isValidElement,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import tunnel from 'tunnel-rat'
import { ScrollContext } from './FiberScroll'
import ScrollHtml from './ScrollHtml'

/**
 * The `Controls`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Controls (props) {
  const {
    children,
    className = '',
    damping = 0.25,
    enabled = true,
    eps = 0.00001,
    horizontal,
    infinite,
    maxSpeed = Infinity,
    maxWidth = 1400,
    scrollPrompt = null,
    scrollPromptTargetPageIndex = 1,
    style = {}
  } = props

  const { events, gl, invalidate, setEvents, size, viewport } = useThree()
  const viewportWidth = viewport.width
  const target = gl.domElement.parentNode

  // Create DOM elements once.
  const el = useRef(document.createElement('div')).current
  const fill = useRef(document.createElement('div')).current
  const fixed = useRef(document.createElement('div')).current
  const htmlRef = useRef()
  const scroll = useRef(0)

  // State management.
  const [pages, setPages] = useState(new Map())
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const [totalScroll, setTotalScroll] = useState(0)
  const [canvasMaxPageWidth, setCanvasMaxPageWidth] = useState(0)
  const [isScrollingDown, setIsScrollingDown] = useState(true)
  const [hasScrolled, setHasScrolled] = useState(false)

  const pageTunnel = useMemo(() => tunnel(), [])

  // Page management functions.
  const addPage = useCallback(index => {
    setPages(lastPages => {
      const newPages = new Map(lastPages)

      newPages.set(index, 'auto')

      return newPages
    })
  }, [])

  const removePage = useCallback(index => {
    setPages(lastPages => {
      const newPages = new Map(lastPages)

      newPages.delete(index)

      return newPages
    })
  }, [])

  const updatePageSize = useCallback((index, height, paddingBottom) => {
    setPages(lastPages => {
      const newPages = new Map(lastPages)

      newPages.set(index, [height, paddingBottom])

      return newPages
    })
  }, [])

  // Build scroll state.
  const state = useMemo(
    () => ({
      el,
      html: htmlRef,
      eps,
      fill,
      fixed,
      horizontal,
      damping,
      offset: 0,
      delta: 0,
      scroll,
      pages,
      setPages,
      totalScroll: 0,
      needsSync: false,
      range (from, distance, margin = 0) {
        const start = from - margin
        const end = start + distance + margin * 2

        return this.offset < start
          ? 0
          : this.offset > end
            ? 1
            : (this.offset - start) / (end - start)
      },
      curve (from, distance, margin = 0) {
        return Math.sin(this.range(from, distance, margin) * Math.PI)
      },
      visible (from, distance, margin = 0) {
        const start = from - margin
        const end = start + distance + margin * 2

        return this.offset >= start && this.offset <= end
      },
      addPage,
      removePage,
      updatePageSize,
      ...pageTunnel
    }),
    [eps, damping, horizontal, pageTunnel, pages, addPage, removePage, updatePageSize, el, htmlRef, fill, fixed]
  )

  // Compute page offsets, heights, and total height.
  const [pageHtmlOffsets, pageHtmlHeights, totalHeight] = useMemo(() => {
    let offset = 0
    const heights = {}
    const offsets = {}
    const sortedEntries = [...pages.entries()].sort((a, b) => a[0] - b[0])

    sortedEntries.forEach(([index, [height, paddingBottom]]) => {
      const isPercent = height.includes('%')
      const heightNum = parseInt(height, 10)
      const pageHeight = isPercent
        ? containerSize.height * (heightNum / 100)
        : heightNum

      offsets[index] = offset
      heights[index] = pageHeight
      offset += pageHeight + paddingBottom
    })

    return [offsets, heights, offset]
  }, [pages, containerSize])

  // Initialize DOM elements and event handling.
  useEffect(() => {
    // Set container styles.
    el.style.position = 'absolute'

    if (className) el.className = className

    el.style.width = '100%'
    el.style.height = '100%'
    el.style[horizontal ? 'overflowX' : 'overflowY'] = 'auto'
    el.style[horizontal ? 'overflowY' : 'overflowX'] = 'hidden'
    el.style.overscrollBehaviorY = 'none'
    el.style.top = '0px'
    el.style.left = '0px'
    Object.assign(el.style, style)

    // Set fixed element styles.
    fixed.style.position = 'sticky'
    fixed.style.pointerEvents = 'all'
    fixed.style.top = '0px'
    fixed.style.left = '0px'
    fixed.style.width = '100%'
    fixed.style.height = '100%'
    fixed.style.overflow = 'hidden'

    fill.style.pointerEvents = 'none'
    fill.appendChild(fixed)
    el.appendChild(fill)
    target.appendChild(el)

    // Offset initial scroll so that it's not at 0.
    el[horizontal ? 'scrollLeft' : 'scrollTop'] = 1

    const oldTarget = events.connected || gl.domElement

    requestAnimationFrame(() => {
      events.connect?.(el)
    })

    // Override compute method for pointer events.
    const oldCompute = events.compute

    setEvents({
      compute (event, state) {
        const { left, top } = target.getBoundingClientRect()
        const offsetX = event.clientX - left
        const offsetY = event.clientY - top

        state.pointer.set(
          (offsetX / state.size.width) * 2 - 1,
          -(offsetY / state.size.height) * 2 + 1
        )

        state.raycaster.setFromCamera(state.pointer, state.camera)
      }
    })

    return () => {
      target.removeChild(el)
      setEvents({ compute: oldCompute })
      events.connect?.(oldTarget)
    }
  }, [])

  // Handle container resize (debounced).
  useEffect(() => {
    const handleResize = () => {
      const newHeight = el.clientHeight
      const newWidth = el.clientWidth

      setContainerSize({ width: newWidth, height: newHeight })
      fixed.style.height = `${newHeight}px`
      fixed.style.setProperty('--scroll-page-height', `${newHeight}px`)
      state.needsSync = true
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [containerSize.height, containerSize.width, state, fixed, el])

  // Update fill element's size based on totalHeight and update totalScroll.
  useEffect(() => {
    if (horizontal) {
      fill.style.width = `${totalHeight}px`
      fill.style.height = '100%'
    } else {
      fill.style.height = `${totalHeight}px`
      fill.style.width = '100%'
    }

    const newTotalScroll = totalHeight - containerSize.height

    setTotalScroll(newTotalScroll)
    state.totalScroll = newTotalScroll
  }, [totalHeight, containerSize.height, horizontal, state, fill])

  // Immediately synchronize scroll state when container size or total height changes.
  // This avoids easing-based jumps during a resize.
  useLayoutEffect(() => {
    const scrollDim = horizontal ? 'scrollLeft' : 'scrollTop'
    const newScrollThreshold = totalHeight - containerSize.height
    // Calculate the new scroll fraction using the current DOM scroll position.
    const current = el[scrollDim]
    const newScrollFraction = newScrollThreshold ? current / newScrollThreshold : 0

    scroll.current = newScrollFraction
    state.offset = newScrollFraction
    state.delta = 0
  }, [containerSize, totalHeight, horizontal, el, state])

  // Set up scroll event listener.
  useEffect(() => {
    let prevScroll = el[horizontal ? 'scrollLeft' : 'scrollTop']
    let disableScroll = false
    let disableTimeout
    const scrollThreshold =
      el[horizontal ? 'scrollWidth' : 'scrollHeight'] - size[horizontal ? 'width' : 'height']

    const onScroll = () => {
      if (!enabled) return

      const current = el[horizontal ? 'scrollLeft' : 'scrollTop']
      const newScroll = current / scrollThreshold

      setIsScrollingDown(newScroll > (prevScroll / scrollThreshold))
      setHasScrolled(current > 50)
      scroll.current = newScroll

      if (infinite) {
        if (!disableScroll) {
          if (current >= scrollThreshold) {
            const damp = 1 - state.offset

            el[horizontal ? 'scrollLeft' : 'scrollTop'] = 1
            scroll.current = state.offset = -damp
            disableScroll = true
          } else if (current <= 0) {
            const damp = 1 + state.offset

            el[horizontal ? 'scrollLeft' : 'scrollTop'] = scrollThreshold
            scroll.current = state.offset = damp
            disableScroll = true
          }
        }

        if (disableScroll) {
          disableTimeout = setTimeout(() => (disableScroll = false), 40)
        }
      }

      prevScroll = current
      invalidate()
    }

    el.addEventListener('scroll', onScroll, { passive: true })

    return () => {
      clearTimeout(disableTimeout)
      el.removeEventListener('scroll', onScroll)
    }
  }, [enabled, infinite, horizontal, invalidate, size, state])

  // Update scroll state on each frame.
  useFrame((_, delta) => {
    const lastOffset = state.offset

    easing.damp(
      state,
      'offset',
      scroll.current,
      damping,
      delta,
      maxSpeed,
      undefined,
      eps
    )

    easing.damp(
      state,
      'delta',
      Math.abs(lastOffset - state.offset),
      damping,
      delta,
      maxSpeed,
      undefined,
      eps
    )

    if (state.delta > eps) invalidate()
  })

  // Scroll-down callback.
  const onScrollDown = useCallback(() => {
    el.scrollTo({
      top:
        pageHtmlOffsets[scrollPromptTargetPageIndex] != null
          ? pageHtmlOffsets[scrollPromptTargetPageIndex] - 100
          : containerSize.height,
      behavior: 'smooth'
    })
  }, [pageHtmlOffsets, scrollPromptTargetPageIndex, containerSize.height])

  // Update canvas max page width based on container and viewport size.
  useEffect(() => {
    setCanvasMaxPageWidth(
      (viewportWidth / containerSize.width) *
        Math.min(containerSize.width, maxWidth) || 0
    )
  }, [viewportWidth, containerSize.width, maxWidth])

  // Build context.
  const ctx = useMemo(
    () => ({
      canvasMaxPageWidth,
      containerHeight: containerSize.height,
      containerWidth: containerSize.width,
      maxWidth,
      pageHtmlHeights,
      pageHtmlOffsets,
      pages,
      state,
      totalHeight,
      totalScroll,
      hasScrolled,
      isScrollingDown
    }),
    [
      canvasMaxPageWidth,
      containerSize,
      maxWidth,
      pageHtmlHeights,
      pageHtmlOffsets,
      pages,
      state,
      totalHeight,
      totalScroll,
      hasScrolled,
      isScrollingDown
    ]
  )

  const pagesWithIndex = Children.toArray(children)
    .filter(child => isValidElement(child))
    .map((child, pageIndex) => cloneElement(child, { pageIndex }))

  return (
    <ScrollContext.Provider value={ctx}>
      <ScrollHtml ref={htmlRef} scrollPrompt={scrollPrompt} onScrollDown={onScrollDown}>
        <pageTunnel.Out />
      </ScrollHtml>
      {pagesWithIndex}
    </ScrollContext.Provider>
  )
}

Controls.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  damping: PropTypes.number,
  distance: PropTypes.number,
  enabled: PropTypes.bool,
  eps: PropTypes.number,
  horizontal: PropTypes.bool,
  infinite: PropTypes.bool,
  maxSpeed: PropTypes.number,
  maxWidth: PropTypes.number,
  scrollPrompt: PropTypes.node,
  scrollPromptTargetPageIndex: PropTypes.number,
  style: PropTypes.shape()
}

export default memo(Controls)
