import { useFrame } from '@react-three/fiber'
import PropTypes from 'prop-types'
import { forwardRef, memo, useRef } from 'react'
import { useScrollPage } from '@/lib/react/FiberScroll/Page/Page'
import useIntersectionObserver from '@/lib/react/Intersection/hooks/useIntersectionObserver'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'

const PageScrollProgress = forwardRef((props, forwardedRef) => {
  const { Tag = 'div', ...rest } = props
  const ref = useFallbackRef(forwardedRef)
  const { isIntersecting } = useIntersectionObserver(ref)
  const { pageState } = useScrollPage()
  const lastProgress = useRef(0)

  useFrame((state, delta) => {
    if (!isIntersecting) {
      return
    }

    const progress = pageState.progress

    if (progress === lastProgress.current) {
      return
    }

    lastProgress.current = progress
    ref.current.style.setProperty('--scroll-progress', progress)
  })

  return (<Tag ref={ref} {...rest} />)
})

PageScrollProgress.displayName = 'PageScrollProgress'

PageScrollProgress.propTypes = {
  Tag: PropTypes.elementType,
  style: PropTypes.object
}

export default memo(PageScrollProgress)
