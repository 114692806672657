import { useMemo } from 'react'
import constants from '@/app/constants'

/**
 * The `useImage`
 * @param {object} props - the input props
 * @returns {object} the context object
 */
function useImage (props) {
  const {
    path: overridePath,
    sizes,
    src: baseSrc,
    srcSet: inputSrcSet,
    transform
  } = props
  const inputSrc = useMemo(
    () => baseSrc.replace(`${constants.PATH_CDN}/`, ''),
    [baseSrc]
  )
  const isResizable = useMemo(
    () => overridePath.includes('imagekit'),
    [overridePath]
  )
  const isRelative = useMemo(() => !inputSrc.includes('http'), [inputSrc])

  const src = useMemo(() => {
    if (!isRelative) {
      return inputSrc
    }

    if (!isResizable || !transform) {
      return `${overridePath || constants.PATH_ASSETS}/${inputSrc}`
    }

    const { height, width } = transform
    const transforms = []

    if (width) {
      transforms.push(`w-${width}`)
    }

    if (height) {
      transforms.push(`h-${height}`)
    }

    const transformed = `tr:${transforms.join(',')}`

    return `${
      overridePath || constants.PATH_ASSETS
    }/${transformed}/${inputSrc}`
  }, [inputSrc, transform, isRelative, overridePath])

  const srcSet = useMemo(() => {
    if (inputSrcSet) {
      return inputSrcSet
    }

    if (!isRelative) {
      return ''
    }

    return sizes
      .map(size => {
        const [width, vw] = Array.isArray(size)
          ? [size[0], size[1]]
          : [size, size * 1.2]

        return `${
          overridePath || constants.PATH_ASSETS
        }/tr:w-${width}/${inputSrc} ${vw}w`
      })
      .join(', ')
  }, [inputSrc, sizes, isRelative, transform, overridePath])

  const ctx = useMemo(
    () => ({
      src,
      srcSet
    }),
    [src, srcSet]
  )

  return ctx
}

// Export
export default useImage
