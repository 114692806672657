import { useEffect, useMemo, useRef, useState } from 'react'

/**
 * The `useLink` hook
 * @param {object} props - the component props
 * @returns {object} the context object
 */
export default function useLink (props) {
  const ref = useRef()
  const [isHover, setIsHover] = useState(false)
  const [isHoverIn, setIsHoverIn] = useState(false)
  const [isHoverOut, setIsHoverOut] = useState(false)
  const lastHover = useRef(false)
  const isTimerRunning = useRef(false)
  const didHoverIn = useRef(false)
  const timer = useRef()

  useEffect(() => {
    if (lastHover.current === isHover) {
      return
    }

    lastHover.current = isHover

    if (isHover) {
      setIsHoverOut(false)

      timer.current = setTimeout(() => {
        setIsHoverIn(true)
        isTimerRunning.current = false
        didHoverIn.current = true
      }, 100)

      isTimerRunning.current = true
    } else {
      if (didHoverIn.current) {
        setIsHoverIn(false)
        setIsHoverOut(true)

        timer.current = setTimeout(() => {
          setIsHoverOut(false)
          isTimerRunning.current = false
        }, 300)

        isTimerRunning.current = true
        didHoverIn.current = false
      }
    }

    return () => clearTimeout(timer.current)
  }, [isHover])

  useEffect(() => {
    lastHover.current = false
  }, [])

  const ctx = useMemo(() => ({
    ref,
    isHoverIn,
    isHoverOut,
    setIsHover
  }), [isHover, isHoverIn,
    isHoverOut])

  return ctx
}
