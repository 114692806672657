import { useFrame } from '@react-three/fiber'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { forwardRef, memo, useRef } from 'react'
import { useScroll } from '@/lib/react/FiberScroll/FiberScroll'
import useIntersectionObserver from '@/lib/react/Intersection/hooks/useIntersectionObserver'

const ScrollRotate = forwardRef((props, forwardedRef) => {
  const { children, factor = 1, offset = 0, ...rest } = props
  const { state: scrollState } = useScroll()
  const ref = useRef()
  const { isIntersecting } = useIntersectionObserver(ref)
  const lastRotate = useRef(0)

  useFrame(() => {
    if (!isIntersecting || !ref.current) {
      return
    }

    const scrollAmount = (scrollState.totalScroll * -scrollState.offset) * factor
    const rotation = scrollAmount + offset

    if (lastRotate.current === rotation) {
      return
    }

    lastRotate.current = rotation

    gsap.set(ref.current, {
      rotate: rotation
    })
  })

  return (
    <div ref={ref} {...rest}>{children}</div>
  )
})

ScrollRotate.displayName = 'ScrollRotate'

ScrollRotate.propTypes = {
  children: PropTypes.node,
  factor: PropTypes.number,
  offset: PropTypes.number
}

export default memo(ScrollRotate)
