import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import useAppData from '@/app/services/hooks/useAppData'
import FX from '@/components/core/FX/FX'
import Layout from '@/components/core/Layout/Layout'
import BlockText from '@/components/elements/BlockText/BlockText'
import ClipWedge from '@/components/elements/ClipWedge/ClipWedge'
import ScrollingBlock from '@/components/elements/ScrollingBlock/ScrollingBlock'
import AutoScroll from '@/components/elements/ScrollingBlock/behaviors/AutoScroll'
import SyncScroll from '@/components/elements/ScrollingBlock/behaviors/SyncScroll'
import SplitTextRows from '@/components/util/SplitTextRows/SplitTextRows'
import SVGRuler from '@/resources/svg/ui/ruler.svg'
import { shuffle } from '@/lib/util/array'
import classNames from '@/lib/util/classNames'
import * as styles from './Content.module.scss'

/**
 *
 * @param {object} props - the props
 * @param {string} props.className - the class name
 * @returns {React.ReactElement} the element
 */
function Content ({ className }) {
  const appData = useAppData()
  const locations = useMemo(
    () => shuffle([...appData.assets.locations]),
    [appData]
  )
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )

  return (
    <Layout.Section className={classNameOutput}>
      <div className={styles.box}>
        <ClipWedge className={styles.shape}>
          <ScrollingBlock
            className={classNames(styles.scroller, styles.scrollerTop)}
            behavior={<AutoScroll factor={0.5} />}
          >
            <div className={styles.items}>
              {locations.map((location, i) => (
                <span key={i}>{location.title}</span>
              ))}
              <span>Buzgul Mine</span>
              <span>Carriagemore</span>
              <span>Qora Graphite Mine</span>
              <span>Qora Graphite Mine</span>
            </div>
          </ScrollingBlock>
          <ScrollingBlock
            className={classNames(styles.scroller, styles.scrollerBottom)}
            behavior={<AutoScroll factor={-0.5} />}
          >
            <div className={styles.items}>
              {locations.map((location, i) => (
                <span key={i}>{location.title}</span>
              ))}
              <span>Buzgul Mine</span>
              <span>Carriagemore</span>
              <span>Qora Graphite Mine</span>
              <span>Qora Graphite Mine</span>
            </div>
          </ScrollingBlock>
        </ClipWedge>
        <div className={styles.content}>
          <FX.Intersect className={styles.title}>
            <div className={styles.inner}>
              <div className={styles.block}>
                <SplitTextRows
                  renderRow={(children, i) => (
                    <ClipWedge
                      className={styles.text}
                      Tag={FX.Wipe}
                      direction="left"
                      key={i}
                      skippable
                    >
                      <BlockText theme="tertiary" Tag="h4">
                        {children}
                      </BlockText>
                    </ClipWedge>
                  )}
                >
                  <BlockText Tag="h4">Our World Class Roster</BlockText>
                </SplitTextRows>
                <SplitTextRows
                  renderRow={(children, i) => (
                    <ClipWedge
                      className={styles.text}
                      Tag={FX.Wipe}
                      key={i}
                      skippable
                    >
                      <BlockText theme="invert">{children}</BlockText>
                    </ClipWedge>
                  )}
                >
                  <BlockText>$5 Billion in assets secured</BlockText>
                </SplitTextRows>
              </div>
            </div>
          </FX.Intersect>
        </div>
      </div>
      <ScrollingBlock
        className={styles.ruler}
        behavior={<SyncScroll factor={-0.1} />}
      >
        <div className={styles.rule}>
          <SVGRuler />
        </div>
      </ScrollingBlock>
    </Layout.Section>
  )
}

Content.propTypes = {
  className: PropTypes.string
}

export default memo(Content)
