import PropTypes from 'prop-types'
import { createContext, useContext, useMemo } from 'react'
import tunnel from 'tunnel-rat'

export const context = createContext()

/**
 * The `useScroll` context
 * @returns {object} the context
 */
export function useWrapper () {
  return useContext(context)
}

/**
 * The `Tunnel` (provides a tunnel out)
 * @param {object} props - the component props
 * @returns {React.ReactElement} the tunnel
 */
function Wrapper (props) {
  const { children } = props
  const t = useMemo(() => tunnel(), [])

  return (
    <context.Provider value={t}>
      <t.Out />
      {children}
    </context.Provider>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node
}

export default Wrapper
