import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import ClipWedge from '../ClipWedge/ClipWedge'
import * as styles from './InfoCard.module.scss'

/**
 * The `InfoCard`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
const InfoCard = forwardRef((props, forwardedRef) => {
  const { children, className, title } = props
  const ref = useFallbackRef(forwardedRef)
  const classNameOutput = useMemo(() => classNames(styles.container, className), [className])

  return (
    <div ref={ref} className={classNameOutput}>
      <ClipWedge className={styles.header}>
        {title}
      </ClipWedge>
      <div className={styles.box}>
        <ClipWedge className={styles.background} />
        <div className={styles.content}>
          {children}
        </div>
      </div>

    </div>
  )
})

InfoCard.displayName = 'InfoCard'

InfoCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string
}

export default memo(InfoCard)
