import { Edges, Float } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { memo, useMemo, useRef } from 'react'
import { damp } from 'three/src/math/MathUtils'
import { useScrollPage } from '@/lib/react/FiberScroll/Page/Page'
import useScrollPageTrackedElement from '@/lib/react/FiberScroll/Page/hooks/useScrollPageTrackedElement'
import { usePointerContext } from '@/lib/react/PointerProvider/PointerProvider'
import getGeometryFromSVGString from '@/lib/three/getGeometryFromSVG'
import token from './token'
import useIsPageInView from '@/hooks/useIsPageInView'
import useOrganicReveal from '@/hooks/useOrganicReveal'

const meshGeometry = getGeometryFromSVGString(token, 0.4, {
  steps: 5,
  depth: 8,
  bevelEnabled: false,
  bevelThickness: 0,
  bevelSize: 0.2,
  bevelOffset: 0,
  bevelSegments: 2
})

/**
 * The `Canvas`
 * @returns {React.ReactElement} the element
 */
function Canvas () {
  const ref = useRef()
  const revealRef = useRef()
  const shapeRef = useRef()
  const pointer = usePointerContext()
  const { pageState } = useScrollPage()
  const backgroundBox = useScrollPageTrackedElement('backgroundBox')
  const scale = useMemo(
    () =>
      Math.max(
        0.01,
        Math.min(backgroundBox.width, backgroundBox.height) * 0.35
      ),
    [backgroundBox]
  )
  const info = useRef({
    pointer: { x: 0, y: 0 }
  })
  const isShown = useIsPageInView()

  useOrganicReveal(
    revealRef,
    isShown,
    {
      x: 0,
      y: -1,
      z: 0,
      scale: 0.55,
      rotationX: 0.5,
      rotationY: 0.5
    },
    1.5
  )

  useFrame((state, delta) => {
    info.current.pointer.x = damp(
      info.current.pointer.x,
      pointer.state.isOver ? (pointer.state.normalX - 0.5) * 2 : 0,
      2,
      delta
    )

    info.current.pointer.y = damp(
      info.current.pointer.y,
      pointer.state.isOver ? (pointer.state.normalY - 0.5) * 2 : 0,
      2,
      delta
    )

    shapeRef.current.rotation.y = damp(
      shapeRef.current.rotation.y,
      0.2 -
        (pageState.progress - 0.5) * 2 * 0.2 +
        info.current.pointer.x * 0.25,
      5,
      delta
    )

    shapeRef.current.rotation.x = damp(
      shapeRef.current.rotation.x,
      -0.75 -
        (pageState.progress - 0.5) * 2 * 0.2 +
        info.current.pointer.y * 0.25,
      5,
      delta
    )
  })

  return (
    <group ref={ref}>
      <group position={backgroundBox.position}>
        <group ref={revealRef}>
          <group ref={shapeRef}>
            <Float
              speed={1}
              rotationIntensity={1}
              floatIntensity={1}
              floatingRange={[-0.1, 0.1]}
            >
              <mesh
                geometry={meshGeometry}
                scale={scale}
                rotation={[-Math.PI * 0.08, -0.3, 0.0]}
              >
                <meshBasicMaterial attach="material" color={'#fff'} />
                <Edges threshold={14} linewidth={2} color={'#b6b6b6'} />
              </mesh>
            </Float>
          </group>
        </group>
      </group>
    </group>
  )
}

export default memo(Canvas)
