import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import * as styles from './Transition.module.scss'
import useTransition from './hooks/useTransition'

const Transition = forwardRef((props, forwardedRef) => {
  const { children, className } = props
  const { ref } = useTransition(props, forwardedRef)
  const classNameOutput = useMemo(
    () => classNames(styles.transition, className),
    [className]
  )

  return (
    <div ref={ref} className={classNameOutput}>
      {children}
    </div>
  )
})

Transition.displayName = 'Transition'

Transition.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  from: PropTypes.shape(),
  to: PropTypes.shape(),
  delay: PropTypes.number,
  skippable: PropTypes.bool
}

export default memo(Transition)
