import { useEffect, useMemo, useRef, useState } from 'react'
import onResize from '@/lib/onResize'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import { getTextNodes, getTextRowContent } from './text'

/**
 * The `useSplitTextRows`
 * @param {object} props - the props
 * @param {object} forwardedRef - the forwarded ref
 * @returns {object} the context object
 */
export default function useSplitTextRows (props, forwardedRef) {
  const { children } = props
  const ref = useFallbackRef(forwardedRef)
  const [rows, setRows] = useState([])
  const measureRef = useRef()
  const len = useMemo(() => rows.length, [rows])

  useEffect(() => {
    const measureEl = measureRef.current
    const handleResize = () => {
      const nodes = getTextNodes(measureEl)
      const res = getTextRowContent(nodes)

      setRows(res)
    }

    const observer = new ResizeObserver(handleResize)

    onResize.on(handleResize, 0)

    handleResize()
    observer.observe(measureEl)

    return () => {
      observer.disconnect()
      onResize.off(handleResize, 0)
    }
  }, [children])

  const ctx = useMemo(
    () => ({
      ref,
      measureRef,
      rows,
      len
    }),
    [rows, len]
  )

  return ctx
}
