import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import ScaledText from '@/components/util/ScaledText/ScaledText'
import classNames from '@/lib/util/classNames'
import { getUniqueId } from '@/lib/util/uniq'
import * as styles from './JumboText.module.scss'

/**
 * The `JumboText`
 * @param {object} props - the props
 * @returns {React.ReactElement} the element
 */
function JumboText (props) {
  const { children, className, fill = null, input: inputText } = props
  const uid = useMemo(() => `svg-${getUniqueId()}`, [])
  const input = inputText || children
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )
  const styleOutput = useMemo(
    () => ({
      '--mask-url': `url(#${uid})`
    }),
    [uid]
  )

  return (
    <div className={classNameOutput} style={styleOutput}>
      <ScaledText className={styles.item} input={input}>
        <div className={styles.output}>
          <div className={styles.background}>{fill}</div>
          <svg width="100%" height="100%">
            <defs>
              <mask id={uid}>
                <text
                  textAnchor="start"
                  fill="white"
                  dominantBaseline="middle"
                >
                  {children}
                </text>
              </mask>
            </defs>
            <text
              textAnchor="start"
              dominantBaseline="middle"
            >
              {children}
            </text>
          </svg>
          <div className={styles.inner}>{children}</div>
        </div>
      </ScaledText>
    </div>
  )
}

JumboText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  input: PropTypes.string,
  fill: PropTypes.node
}

export default memo(JumboText)
