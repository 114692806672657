import { useFrame } from '@react-three/fiber'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import { memo, useRef } from 'react'
import { useScroll } from '@/lib/react/FiberScroll/FiberScroll'
import { useScrollingBlockContext } from '../context'

/**
 * The `SyncScroll`
 * @param {object} props - the props
 * @param {number} props.factor - the factor
 * @returns {React.ReactElement} the element
 */
function SyncScroll (props) {
  const { factor = 1 } = props
  const { isIntersecting, listRef, state } = useScrollingBlockContext()
  const { state: scrollState } = useScroll()
  const lastX = useRef(0)

  useFrame(() => {
    if (!isIntersecting || !listRef.current) {
      return
    }

    const scrollAmount = scrollState.totalScroll * -scrollState.offset * factor
    const len = state.width
    const x = ((((len + scrollAmount) % len) + len) % len) - len

    if (lastX.current === x) {
      return
    }

    lastX.current = x

    gsap.set(listRef.current, {
      x
    })
  })

  return <></>
}

SyncScroll.propTypes = {
  factor: PropTypes.number
}

export default memo(SyncScroll)
