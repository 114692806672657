import PropTypes from 'prop-types'
import { createContext, memo, useContext, useMemo } from 'react'
import useDelayedActiveState from '../../hooks/useDelayedActiveState'
const ScrollPromptContext = createContext({})

export const useScrollPromptContext = () => {
  const context = useContext(ScrollPromptContext)

  return context
}

/**
 * The `ScrollPrompt`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function ScrollPrompt (props) {
  const { children, hasScrolled, onScrollDown } = props
  const isActive = useDelayedActiveState(!hasScrolled, 0.5, 0.02)
  const isVisible = useDelayedActiveState(!hasScrolled, 0, 0.5)

  const ctx = useMemo(
    () => ({
      onScrollDown,
      isVisible,
      isActive
    }),
    [onScrollDown, isVisible, isActive]
  )

  return (
    <ScrollPromptContext.Provider value={ctx}>
      {children}
    </ScrollPromptContext.Provider>
  )
}

/** @type {object} */
ScrollPrompt.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  hasScrolled: PropTypes.bool,
  onScrollDown: PropTypes.func
}

// Memoize
export default memo(ScrollPrompt)
