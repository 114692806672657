import { cleanRect } from '@/lib/util/rect'

export const getTextNodes = (el, ignoreContainerSelector = null) => {
  const res = []
  const filter = {
    acceptNode: node => {
      // If an optional selector is provided, check if the node is contained within it.
      if (ignoreContainerSelector && node.parentElement.closest(ignoreContainerSelector)) {
        return NodeFilter.FILTER_SKIP
      }

      // Check if the node's parent or any ancestor is aria-hidden.
      if (node.parentElement && node.parentElement.closest('[aria-hidden="true"]')) {
        return NodeFilter.FILTER_SKIP
      }

      return NodeFilter.FILTER_ACCEPT
    }
  }

  const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, filter, false)
  let n

  while ((n = walk.nextNode())) {
    res.push(n)
  }

  return res
}

/*
 * Measure client rects of text range, which is an array of DOMRect corresponding to each word
 */
/**
 * @param {object} range - the range
 * @returns {Array} the array of DOMRect corresponding to each word
 */
function getRangeRects (range) {
  const rects = Array.from(range.getClientRects())

  return rects.map(cleanRect)
}

/*
 * Get space rect's so that they can be used to trim the end of line breaks later,
 * for example: "end of line " > "end of line"
 */
/**
 *
 * @param {object} range - the range
 * @param {Array} textNodes - the text nodes
 * @returns {Array} the space rects
 */
function getSpaceRects (range, textNodes) {
  return textNodes.reduce((res, textNode) => {
    ;[...textNode.textContent.matchAll(/ /g)].forEach(match => {
      range.setStart(textNode, match.index)
      range.setEnd(textNode, match.index + 1)
      res.push(cleanRect(range.getBoundingClientRect()))
    })

    return res
  }, [])
}

/**
 * @param {Array} textRects - the text rects
 * @param {Array} spaceRects - the space rects
 * @returns {Array} the merged rects
 */
const mergeRectsByLine = (textRects, spaceRects) => {
  let { rows } = textRects.reduce(
    (res, rect) => {
      let currentRow = res.rows[res.rowIndex]

      if (
        !currentRow ||
        (currentRow.y !== rect.y &&
          (currentRow.y < rect.y ||
            currentRow.y + currentRow.height > rect.y + rect.height) &&
          Math.abs(currentRow.endX - rect.x) > 1)
      ) {
        res.rowIndex += 1

        res.rows[res.rowIndex] = {
          left: rect.left,
          right: rect.right,
          top: rect.top,
          bottom: rect.bottom,
          x: rect.x,
          endX: rect.x,
          y: rect.y,
          width: 0,
          height: rect.height
        }

        currentRow = res.rows[res.rowIndex]
      }

      currentRow.endX = Math.max(currentRow.endX, rect.right)
      currentRow.width = Math.max(0, currentRow.endX - currentRow.x)

      return res
    },
    {
      rowIndex: -1,
      rows: []
    }
  )

  rows.sort((a, b) => b.width - a.width).sort((a, b) => b.y - a.y)

  rows = rows.reduce((res, row) => {
    if (res.length) {
      const previousRow = res[res.length - 1]

      if (
        (row.y === previousRow.y && row.x === previousRow.x) ||
        (row.y === previousRow.y && row.endX === previousRow.endX)
      ) {
        return res
      }
    }

    const foundWhitespaceIndex = spaceRects.findIndex(
      spaceRect => row.endX === spaceRect.right && row.y === spaceRect.y
    )

    if (~foundWhitespaceIndex) {
      row.width -= spaceRects[foundWhitespaceIndex].width
    }

    res.push(row)

    return res
  }, [])

  return rows
}

/**
 * Gets the text row rectangles. This method takes an input array of `textNodes`,
 * specifically those provided from the corresponding `getTextNodes` method.
 * @param {Array} textNodes - the input text nodes
 * @returns {object} an object with the scale and the array of row rectangles
 */
export const getTextRowRects = textNodes => {
  if (!textNodes || !textNodes.length) {
    return []
  }

  const range = document.createRange()

  range.setStart(textNodes[0], 0)

  range.setEnd(
    textNodes[textNodes.length - 1],
    textNodes[textNodes.length - 1].length
  )

  const textRects = getRangeRects(range)
  const scale = 1
  const spaceRects = getSpaceRects(range, textNodes)
  const rows = mergeRectsByLine(textRects, spaceRects)

  range.detach()

  return {
    scale,
    rowRects: rows.reverse().map((row, i) => {
      row.index = i

      return row
    })
  }
}

/**
 * Gets the text content for each visual row of text.
 * It first creates a full range covering the provided text nodes,
 * then iterates character-by-character to determine when the vertical
 * position (the "top" value) changes—indicating a new row.
 * @param {Array} textNodes - the input text nodes (as provided by getTextNodes)
 * @returns {Array} an array of strings, where each string is the text content of a row.
 */
export const getTextRowContent = textNodes => {
  if (!textNodes || !textNodes.length) {
    return []
  }

  // Create a range that spans all provided text nodes.
  const range = document.createRange()

  range.setStart(textNodes[0], 0)

  const lastNode = textNodes[textNodes.length - 1]

  range.setEnd(lastNode, lastNode.length)

  // The full text content across all text nodes.
  const fullText = range.toString()

  // Build a mapping from global text offset to its text node and local offset.
  const indexMapping = []
  let currentIndex = 0

  textNodes.forEach(textNode => {
    const len = textNode.textContent.length

    indexMapping.push({ node: textNode, start: currentIndex, end: currentIndex + len })
    currentIndex += len
  })

  // Helper: given a global offset, find the corresponding text node mapping.
  const getMappingForOffset = offset => {
    for (const mapping of indexMapping) {
      if (offset >= mapping.start && offset < mapping.end) {
        return mapping
      }
    }

    return null
  }

  // Temporary range used for measuring individual characters.
  const charRange = document.createRange()
  const threshold = 2 // pixel difference to consider a new row
  const rowContents = []
  let currentRow = ''
  let currentLineTop = null

  // Loop through each character in the full text.
  for (let offset = 0; offset < fullText.length; offset++) {
    const mapping = getMappingForOffset(offset)

    if (!mapping) continue

    const node = mapping.node
    const localOffset = offset - mapping.start

    charRange.setStart(node, localOffset)
    charRange.setEnd(node, localOffset + 1)

    const rect = charRange.getBoundingClientRect()

    // If the rect is “empty” (e.g. for collapsed whitespace), still include the character.
    const charTop = rect.top || currentLineTop

    if (currentLineTop === null) {
      currentLineTop = charTop
    }

    // If the top position changes beyond our threshold, we start a new row.
    if (Math.abs(charTop - currentLineTop) > threshold) {
      rowContents.push(currentRow)
      currentRow = fullText[offset]
      currentLineTop = charTop
    } else {
      currentRow += fullText[offset]
    }
  }

  if (currentRow) {
    rowContents.push(currentRow)
  }

  // Cleanup
  charRange.detach()
  range.detach()

  return rowContents
}
