import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import FX from '@/components/core/FX/FX'
import Layout from '@/components/core/Layout/Layout'
import ClipWedge from '@/components/elements/ClipWedge/ClipWedge'
import classNames from '@/lib/util/classNames'
import * as styles from './Content.module.scss'

/**
 * The `Content`
 * @param {object} props - the component props
 * @param {string} props.className - the class name
 * @returns {React.ReactElement} the element
 */
function Content ({ className }) {
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )

  return (
      <Layout.Section className={classNameOutput}>
        <FX.Intersect className={styles.items}>
          <ClipWedge className={styles.item}>
            <FX.Wipe direction="up">
              <h4 className={styles.bar}>Invest</h4>
            </FX.Wipe>
          </ClipWedge>
          <ClipWedge className={styles.item}>
            <FX.Wipe direction="up" delay={0.1}>
            <h4 className={styles.bar}>Earn</h4>
            </FX.Wipe>
          </ClipWedge>
          <ClipWedge className={styles.item}>
            <FX.Wipe direction="up" delay={0.2}>
            <h4 className={styles.bar}>Stake</h4>
            </FX.Wipe>
          </ClipWedge>
        </FX.Intersect>
      </Layout.Section>
  )
}

Content.propTypes = {
  className: PropTypes.string
}

export default memo(Content)
