import { memo, useMemo } from 'react'
import useAppData from '@/app/services/hooks/useAppData'
import Button from '@/components/core/Button/Button'
import FX from '@/components/core/FX/FX'
import Icon from '@/components/core/Icon/Icon'
import Layout from '@/components/core/Layout/Layout'
import Link from '@/components/core/Link/Link'
import BlockText from '@/components/elements/BlockText/BlockText'
import ClipWedge from '@/components/elements/ClipWedge/ClipWedge'
import ScrollingBlock from '@/components/elements/ScrollingBlock/ScrollingBlock'
import SyncScroll from '@/components/elements/ScrollingBlock/behaviors/SyncScroll'
import SplitTextRows from '@/components/util/SplitTextRows/SplitTextRows'
import SVGRuler from '@/resources/svg/ui/ruler.svg'
import * as styles from './Footer.module.scss'

/**
 * The `Footer`
 * @returns {React.ReactElement} the element
 */
function Footer () {
  const appData = useAppData()
  const socialOutput = useMemo(
    () =>
      appData.social.map((social, index) => (
        <li key={index}>
          <a
            href={social.url}
            target="_blank"
            rel="noreferrer"
            title={social.label}
          >
            <Icon icon={social.icon} />
          </a>
        </li>
      )),
    []
  )

  return (
    <footer className={styles.container}>
      <Layout.Section>
        <ScrollingBlock
          className={styles.ruler}
          behavior={<SyncScroll factor={-0.1} />}
        >
          <div className={styles.rule}>
            <SVGRuler />
          </div>
        </ScrollingBlock>
        <div className={styles.grid}>
          <FX.Intersect className={styles.header}>
            <SplitTextRows
              renderRow={(children, i) => (
                <ClipWedge Tag={FX.Wipe} key={i} skippable>
                  <BlockText>{children}</BlockText>
                </ClipWedge>
              )}
            >
              <BlockText>Launching Soon</BlockText>
            </SplitTextRows>

            <FX.Wipe delay={0.1} direction="left">
              <Button>Sign up for updates</Button>
            </FX.Wipe>
          </FX.Intersect>

          <div className={styles.nav}>
          <SplitTextRows
              renderRow={(children, i) => (
                <ClipWedge Tag={FX.Wipe} key={i} skippable>
                  <BlockText Tag="h4">{children}</BlockText>
                </ClipWedge>
              )}
            >
              <BlockText Tag="h4">Links</BlockText>
            </SplitTextRows>
            <ul className={styles.links}>
              {appData.sections.map(({ id, label }, i) => (
                <li key={i}>
                  <Link className={styles.link} href={`#${id}`}>{label}</Link>
                </li>
              ))}
            </ul>
          </div>

          <ul className={styles.social}>{socialOutput}</ul>

          <div className={styles.copyright}>© Eldorado 2025</div>
        </div>
      </Layout.Section>
    </footer>
  )
}

export default memo(Footer)
