import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import classNames from '@/lib/util/classNames'
import * as styles from './ClipWedge.module.scss'

/**
 * The `ClipWedge` component is a simple wrapper that clips the content inside it
 * with a wedge shape.
 * @param {object} props - the props
 * @param {string} props.className - the class name
 * @param {React.ReactNode} props.children - the children
 * @param {string} props.Tag - the tag name
 * @returns {React.ReactElement} the element
 */
function ClipWedge (props) {
  const { Tag = 'div', children, className, ...rest } = props
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )

  return (
    <Tag className={classNameOutput} {...rest}>
      {children}
    </Tag>
  )
}

ClipWedge.propTypes = {
  Tag: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string
}

export default memo(ClipWedge)
