import PropTypes from 'prop-types'
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import noop from '@/lib/util/noop'

// Create the context
const ImgContext = createContext({
  onLoad: noop,
  onError: noop
})

// Export the context as a hook for quick usage
export const useImgContext = () => {
  const context = useContext(ImgContext)

  return context
}

/**
 * The `ImgContextWrapper` allows for a parent node to be able
 * to inherit attributes from a child image load event. It's simply
 * a quick way to have the attributes (namely `data-img-l`, indicating loaded)
 * be available to a parent node if preferred, such as for use in reveal
 * animations
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function ImgContextWrapper (props) {
  const { Tag, children, ...rest } = props
  const [hasLoaded, setHasLoaded] = useState(false)
  const [hasError, setHasError] = useState(false)
  /**
   * On load
   */
  const onLoad = useCallback(() => {
    setHasLoaded(true)
    setHasError(false)
  }, [])

  /**
   * On error
   */
  const onError = useCallback(() => {
    setHasError(true)
  }, [])

  /**
   * Memo: The context object
   */
  const ctx = useMemo(
    () => ({
      hasLoaded,
      hasError,
      onLoad,
      onError
    }),
    [hasLoaded, hasError]
  )

  return (
    <ImgContext.Provider value={ctx}>
      <Tag {...rest}>{children}</Tag>
    </ImgContext.Provider>
  )
}

/** @type {object} */
ImgContextWrapper.propTypes = {
  children: PropTypes.node,
  Tag: PropTypes.elementType
}

/** @type {object} */
ImgContextWrapper.defaultProps = {
  children: null,
  Tag: 'div'
}

// Memoize
export default memo(ImgContextWrapper)
