import gsap from 'gsap'
import { useEffect, useMemo, useRef } from 'react'
import { useLoader } from '@/components/interface/Loader/Provider/Provider'
import useDelayedActiveState from '@/lib/react/hooks/useDelayedActiveState'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import { useFXContext } from '../../context'

const DEFAULT_FROM = { opacity: 0, y: 100 }
const DEFAULT_TO = { opacity: 1, y: 0 }

/**
 * The `useTransition` hook
 * @param {object} props - the input props
 * @param {object} forwardedRef - the forwarded ref
 * @returns {object} the context
 */
export default function useTransition (props, forwardedRef) {
  const {
    delay = 0,
    duration = 0.3,
    from = DEFAULT_FROM,
    skippable = false,
    to = DEFAULT_TO
  } = props
  const { isReady } = useLoader()
  const hasLoaded = useDelayedActiveState(isReady, 0.5)
  const { isActive: isFXActive } = useFXContext()
  const isActive = useDelayedActiveState(isFXActive && hasLoaded, delay, 0, skippable)
  const ref = useFallbackRef(forwardedRef)
  const wasActive = useRef(isFXActive && hasLoaded)

  useEffect(() => {
    const el = ref.current

    if (isActive && isActive === wasActive.current) {
      gsap.killTweensOf(el)
      gsap.set(el, from)
    }

    wasActive.current = isActive

    if (!isActive) {
      gsap.killTweensOf(el)
      gsap.set(el, from)

      return
    }

    gsap.to(el, {
      duration,
      ...to
    })

    return () => {
      gsap.killTweensOf(el)
    }
  }, [isActive, duration, from, to])

  const ctx = useMemo(
    () => ({
      ref
    }),
    []
  )

  return ctx
}
