import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import classNames from '@/lib/util/classNames'
import * as styles from './BlockText.module.scss'

const BlockText = forwardRef((props, forwardedRef) => {
  const {
    Tag = 'h1',
    children,
    className,
    size = 'std',
    theme
  } = props
  const ref = useFallbackRef(forwardedRef)
  const classNameOutput = useMemo(
    () =>
      classNames(
        styles.container,
        className,
        styles[(`size-${size}`)],
        styles[`theme-${theme}`]
      ),
    [className, size, theme]
  )

  return (
    <Tag ref={ref} className={classNameOutput}>
      {children}
    </Tag>
  )
})

BlockText.displayName = 'BlockText'

BlockText.propTypes = {
  Tag: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.string,
  theme: PropTypes.string
}

export default memo(BlockText)
