import { memo } from 'react'
import FiberScroll from '@/lib/react/FiberScroll/FiberScroll'
import Content from './Content/Content'

/**
 * The `Assets`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function Assets (props) {
  return (
    <FiberScroll.Page {...props} paddingBottom={150}>
      <FiberScroll.Page.Canvas />
      <FiberScroll.Page.HTML fullHeight={false}>
        <Content />
      </FiberScroll.Page.HTML>
    </FiberScroll.Page>
  )
}

export default memo(Assets)
