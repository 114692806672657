import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import useAppData from '@/app/services/hooks/useAppData'
import Layout from '@/components/core/Layout/Layout'
import InfoCard from '@/components/elements/InfoCard/InfoCard'
import ScrollingBlock from '@/components/elements/ScrollingBlock/ScrollingBlock'
import SyncScroll from '@/components/elements/ScrollingBlock/behaviors/SyncScroll'
import SlideScroll from '@/components/elements/SlideScroll/SlideScroll'
import SVGBars from '@/resources/svg/ui/bars.svg'
import classNames from '@/lib/util/classNames'
import * as styles from './Content.module.scss'

/**
 * The `Content`
 * @param {object} props - the component props
 * @param {string} props.className - the class name
 * @returns {React.ReactElement} the element
 */
function Content ({ className }) {
  const appData = useAppData()
  const classNameOutput = useMemo(
    () => classNames(className, styles.container),
    [className]
  )

  return (
    <Layout.Section className={classNameOutput}>
      <div className={styles.content}>
        <SlideScroll>
          {appData.tokenDetail.items.map((item, index) => (
            <InfoCard className={styles.card} key={index} title={item.title}>
              {item.content}
            </InfoCard>
          ))}
        </SlideScroll>
        <ScrollingBlock behavior={<SyncScroll factor={0.2} />}>
          <div className={styles.bars}>
            <SVGBars />
          </div>
        </ScrollingBlock>
      </div>
    </Layout.Section>
  )
}

Content.propTypes = {
  className: PropTypes.string
}

export default memo(Content)
