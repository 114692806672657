import '@/styles/index.scss'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ROOT } from '@/app/thread'
import PointerProvider from '@/lib/react/PointerProvider/PointerProvider'
import App from './App'
import RootProvider from './app/services/RootProvider/RootProvider'
import Loader from './components/interface/Loader/Loader'

const root = createRoot(ROOT)

root.render(
  <BrowserRouter>
    <RootProvider>
      <Loader.Provider>
        <PointerProvider>
          <App />
        </PointerProvider>
      </Loader.Provider>
    </RootProvider>
  </BrowserRouter>
)
